angular.module('seatonApp')
       .directive('validateEmail', [validateEmail]);

function validateEmail() {

    var EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    // most common domains taken from t_email_address
    var KNOWN_EMAIL_DOMAINS = [
        "gmail.com", "yahoo.com", "hotmail.com", "aol.com", "comcast.net", "icloud.com",
        "msn.com", "me.com", "outlook.com", "sbcglobal.net", "live.com", "verizon.net",
        "att.net", "mac.com", "cox.net", "ymail.com", "hotmail.co.uk", "btinternet.com",
        "yahoo.co.uk", "sky.com", "protonmail.com", "googlemail.com", "live.co.uk",
        "benefis.org", "colostate.edu"
    ];

    function isEmailDomainValid(domain) {

        if (!domain) {
            return false;
        } else if (KNOWN_EMAIL_DOMAINS.indexOf(domain) >= 0) {
            return true;
        }

        var xhttp = new XMLHttpRequest();

        xhttp.open("GET", 'api/v1/public/domain_validation?domain=' + domain, false);
        xhttp.send(null);

        if (xhttp.status === 200) {
            var responseObj = JSON.parse(xhttp.responseText);
            if (responseObj.valid === true) {
                return true;
            }
        }
        return false;
    }

    function isEmailValid(email) {
        // must have a '@' and a '.' inside
        if (!email || !EMAIL_REGEX.test(email)) {
            console.log('Regex check FAIL (or empty)');
            return false;
        }
        var emailDomain = email.substring(email.indexOf('@') + 1);
        return isEmailDomainValid(emailDomain);
    }

    return {
        require: '?ngModel',
        link: function(scope, elm, attrs, ctrl) {
            // only apply the validator if ngModel is present and Angular has added the email validator
            if (ctrl && ctrl.$validators.email) {
                // this will overwrite the default Angular email validator
                ctrl.$validators.email = function (modelValue) {
                    var valid = isEmailValid(modelValue);
                    console.log('Email: ' + modelValue + ", valid: " + valid);
                    return ctrl.$isEmpty(modelValue) || valid;
                };
            }
        }
    }
}
