(function () {
    'use strict';

    angular
        .module('seatonApp')
        .controller('TimeSlotController', TimeSlotController);

    TimeSlotController.$inject = ['$scope', '$state', 'SettingsResource', 'Log', '$location', '$uibModal', '$document',
                                'ApplicationContext', 'ListUtils', 'SweetAlert', 'Utils'];

    var DATE_FORMAT = 'YYYY-MM-DD';
    var TIME_SERVER_FORMAT = 'HH:mm:ss.SSS';
    var TIME_DISPLAY_FORMAT = 'HH:mm';
    var DATE_PICKER_FORMAT = 'MMM D, YYYY';

    function TimeSlotController($scope, $state, SettingsResource, Log, $location, $uibModal, $document,
                               ApplicationContext, ListUtils, SweetAlert, Utils) {

        var vm = this;
        vm.formatMinutes = Utils.formatMinutes;
        vm.formatAmount = Utils.formatAmount;

        vm.timeStepList = ListUtils.getTimeStepList();
        $scope.createTimeslotListModelVisible = false;


        SettingsResource.getTimeSlotSetList({}, onLoadSuccess, onError);

        function onLoadSuccess(data, headers) {
            vm.timeSlotSetList = data;
        }

        SettingsResource.getTimeSlotSettings({}, onSuccess, onError);


        function onSuccess(data, headers) {
            vm.data = data;
        }

        function onError(error) {
            Log.debug("settings.timeslot", "HTTP request error." + error);
        }


        vm.saveOfflineSettings = function() {
            SettingsResource.saveOfflineTimeSlotSettings(vm.data);
        }

        vm.saveOnlineSettings = function() {
            SettingsResource.saveOnlineTimeSlotSettings(vm.data);
        }

        vm.saveLimitationTimeSlotSettings = function() {
            SettingsResource.saveLimitationTimeSlotSettings(vm.data);
        }

        $scope.showCreateTimeSlotList = function(){
            vm.newTimeSlotList = {
                limitPolicy: 'NONE',
                    limitScope: 'ONLINE_ONLY'
            }
            $scope.createTimeslotListModelVisible = true;
        }

        $scope.showUpdateTimeSlotList = function(timeSlotSet){
            vm.newTimeSlotList = timeSlotSet;
            $scope.createTimeslotListModelVisible = true;
        }

        $scope.hideCreateTimeSlotList = function(){
            $scope.createTimeslotListModelVisible = false;
        }

        $scope.createTimeSlotList = function(){
            SettingsResource.saveTimeSlotSet(vm.newTimeSlotList, onSuccessRefresh);

            function onSuccessRefresh(data, headers) {
                vm.timeSlotSetList = data;
                $scope.createTimeslotListModelVisible = false;
            }

        }


        vm.manageTimeSlotSet = function (timeSlotSet) {

            SettingsResource.timeSlotSetDetails({'timeSlotSetId': timeSlotSet.id}, onSuccessModal, onError);

            vm.manageTimeSlotModal = $uibModal.open({
                templateUrl: 'app/settings/timeslot/timeslot_manage.html',
                scope: $scope,
                size: 'lg'
            });
        }

        vm.addTimeSlot = function(setId, dayId, lim, hours, mins, amPm) {
            var definedTime = hours + ':' + mins + ' '+ amPm;
            var slotToAdd = {
                timeSlotSetId: setId,
                dayInWeek: dayId,
                slotTime: definedTime,
                slotLimit: lim
            }

            SettingsResource.saveTimeSlot(slotToAdd, onSuccessModal, onError);

        }

        vm.deleteTimeSlotSet = function(timeSlotSetId) {
            SweetAlert.swal({
                    title: "Confirm action",
                    text: "Do you want to delete time slot set?",
                    type: "warning",
                    showCancelButton: true,
                    cancelButtonColor: "#ec4758",
                    confirmButtonColor: "#1ab394",
                    cancelButtonText: "No",
                    confirmButtonText: "Yes",
                    closeOnConfirm: true
                },
                function (isConfirm) {
                    if (isConfirm) {
                        SettingsResource.deleteTimeSlotSet({'timeSlotSetId': timeSlotSetId}, onSuccessDelete, onError);
                    }
                });

            function onSuccessDelete(data, headers) {
                if(data) onLoadSuccess(data, headers);
            }

        }

        vm.deleteTimeSlot = function(timeSlot) {

            SettingsResource.deleteTimeSlot(timeSlot, onSuccessModal, onError);

        }

        function onSuccessModal(data, headers) {
            vm.newSlot = {
                maxReservationsSlot: -1
            };
            vm.timeSlotSetModal = data;
        }


        $scope.closeTimeSlotManageModal = function() {
            vm.manageTimeSlotModal.dismiss('cancel');
        }

        vm.validTimeSlotEntry = function() {
            if(vm.newSlot) {
                if(vm.newSlot.hour && vm.newSlot.mins && vm.newSlot.amPm) return false;
            }
            return true;
        }

        vm.definitionDesc = function(scope) {
            if(scope) return "Same for each day in week";
            else return "Different for each day in week";
        }

        vm.limitPolicyDesc = function(limitPolicy) {
            if(limitPolicy=='PERSON') return "Per person";
            else if (limitPolicy=='PERSON') return "Per event";
            else return "None";
        }

        vm.limitScopeDesc = function(limitScope) {
            if(limitScope=='ONLINE_ONLY') return "Consider only online reservations";
            else return "Consider all reservations";
        }


        vm.maxReservationSlotList = [
            {id: -1, name: 'No restriction'},
            {id: 1, name: '1 per time slot'},
            {id: 2, name: '2 per time slot'},
            {id: 3, name: '3 per time slot'},
            {id: 4, name: '4 per time slot'},
            {id: 5, name: '5 per time slot'},
            {id: 6, name: '6 per time slot'},
            {id: 7, name: '7 per time slot'},
            {id: 8, name: '8 per time slot'},
            {id: 9, name: '9 per time slot'},
            {id: 10, name: '10 per time slot'},
            {id: 11, name: '11 per time slot'},
            {id: 12, name: '12 per time slot'},
            {id: 13, name: '13 per time slot'},
            {id: 14, name: '14 per time slot'},
            {id: 15, name: '15 per time slot'},
            {id: 16, name: '16 per time slot'},
            {id: 17, name: '17 per time slot'},
            {id: 18, name: '18 per time slot'},
            {id: 19, name: '19 per time slot'},
            {id: 20, name: '20 per time slot'},
            {id: 25, name: '25 per time slot'},
            {id: 30, name: '30 per time slot'},
            {id: 35, name: '35 per time slot'},
            {id: 40, name: '40 per time slot'},
            {id: 45, name: '45 per time slot'},
            {id: 50, name: '50 per time slot'},
            {id: 55, name: '55 per time slot'},
            {id: 60, name: '60 per time slot'},
            {id: 65, name: '65 per time slot'},
            {id: 70, name: '70 per time slot'},
            {id: 75, name: '75 per time slot'},
            {id: 80, name: '80 per time slot'},
            {id: 85, name: '85 per time slot'},
            {id: 90, name: '90 per time slot'},
            {id: 95, name: '95 per time slot'},
            {id: 100, name: '100 per time slot'},
            {id: 125, name: '125 per time slot'},
            {id: 150, name: '150 per time slot'},
            {id: 175, name: '175 per time slot'},
            {id: 200, name: '200 per time slot'}
        ];

        vm.maxReservationsSlotScopeList = [
            {id: 'ALL', name: 'consider all reservations.'},
            {id: 'ONLINE_ONLY', name: 'consider only online reservations.'}
        ];

        vm.maxReservationsSlotScopeListOnline = [
            {id: 'ONLINE_ONLY', name: 'consider only online reservations.'}
        ];


        vm.timeSlotScopeTypes = [
            {id: 'ALL', name: 'All channels'},
            {id: 'ONLINE', name: 'Online channel only'},
            {id: 'NON_ONLINE', name: 'Non-online channels'}
        ];

        vm.limitationPolicyType = [
            {id: 'NONE', name: 'No limitation'},
            {id: 'EVENT', name: 'Limit per number of reservation events'},
            {id: 'PERSON', name: 'Limit per number of persons'}
        ];

        vm.getLimitPolicyDesc = function(policy) {
            var find = vm.limitationPolicyType.find(function(element, index) {
               if(element.id == policy) return true;
            });

            if(find) return find.name;
            return "No policy";
        }

        vm.daysInWeek = [
            {id: 1, name: 'Monday'},
            {id: 2, name: 'Tuesday'},
            {id: 3, name: 'Wednesday'},
            {id: 4, name: 'Thursday'},
            {id: 5, name: 'Friday'},
            {id: 6, name: 'Saturday'},
            {id: 7, name: 'Sunday'}
        ];

        vm.minBeforeList  = [
            {id: -1, name: 'meal duration before (*)'},
            {id: 0, name: 'same as'},
            {id: 15, name: '15 minutes before'},
            {id: 30, name: '30 minutes before'},
            {id: 45, name: '45 minutes before'},
            {id: 60, name: '1 hour before'},
            {id: 75, name: '1 hour 15 minutes before'},
            {id: 90, name: '1 hour 30 minutes before'},
            {id: 105, name: '1 hour 45 minutes before'},
            {id: 120, name: '2 hours before'},
            {id: 150, name: '2 hours 30 minutes before'},
            {id: 180, name: '3 hours before'},
            {id: 240, name: '4 hours before'}
        ];

    }
})();



