(function () {
    'use strict';

    angular
        .module('seatonApp')
        .controller('NotificationController', NotificationController);

    NotificationController.$inject = ['$scope', '$state', 'SettingsResource', 'Log', '$uibModal', 'ApplicationContext', 'ApplicationProfile', '$http', 'SweetAlert'];

    function NotificationController($scope, $state, SettingsResource, Log, $uibModal, ApplicationContext, ApplicationProfile, $http, SweetAlert) {

        var vm = this;

        SettingsResource.getEmailLookAndFeel(
            {},
            function (data) {
                vm.lnf = data;
                Log.info("NotificationController", "Fetched email settings: " + JSON.stringify(data));
            },
            function (error) {
                Log.debug("NotificationController", "Error while fetching email look and feel settings: " + error);
            }
        );

        vm.subscription = ApplicationContext.getLocation().subscription;
        vm.openWindow = ApplicationProfile.openAppCloverPage;
        vm.locationId = ApplicationContext.getLocationID();
        vm.uploadUrl = '/api/v1/merchants/' + vm.locationId + '/emailLookAndFeel/upload';
        vm.imageCacheBuster = new Date().getTime();

        $scope.textSizes = [
            {value: 8, text: "8px"},   {value: 10, text: "10px"}, {value: 12, text: "12px"},
            {value: 14, text: "14px"}, {value: 16, text: "16px"}, {value: 18, text: "18px"},
            {value: 20, text: "20px"}, {value: 22, text: "22px"}, {value: 24, text: "24px"},
            {value: 26, text: "26px"}, {value: 28, text: "28px"}, {value: 30, text: "30px"},
            {value: 32, text: "32px"}, {value: 34, text: "34px"}, {value: 36, text: "36px"},
            {value: 38, text: "38px"}, {value: 40, text: "40px"}, {value: 42, text: "42px"}
        ];

        $scope.fonts = [
            {value: "Helvetica,Arial,sans-serif", text: "Helvetica"},
            {value: "Arial,Helvetica,sans-serif", text: "Arial"},
            {value: "Verdana,sans-serif", text: "Verdana"},
            {value: "Tahoma,sans-serif", text: "Tahoma"},
            {value: "Times New Roman, serif", text: "Times New Roman"},
            {value: "monospace", text: "monospace"},
            {value: "cursive", text: "cursive"},
            {value: "fantasy", text: "fantasy"}
        ];


        SettingsResource.getNotificationSettings({
        }, onSuccessGetData, onErrorGetData);

        function onSuccessGetData(data, headers) {

            $scope.data = data;
            console.log('$scope.data', $scope.data);

            Log.debug("NotificationController", "HTTP request succeed. Data=" + data.id);
        }

        function onErrorGetData(error) {
            Log.debug("NotificationController", "HTTP request error." + error);
        }


        // save data
        $scope.saveData = function () {
            console.log('save data', $scope.data);
            SettingsResource.saveNotificationSettings($scope.data, onSuccessGetData, onErrorGetData);
            SettingsResource.updateEmailLookAndFeel(vm.lnf);

        }

        // restore default values for email notification look and feel
        $scope.restoreLnfSettings = function () {
            vm.lnf.headerNameFontSize=32;
            vm.lnf.headerAddressFontSize=16;
            vm.lnf.headerTextColor="#ffffff";
            vm.lnf.headerFontFamily="Helvetica,Arial,sans-serif";
            vm.lnf.headerBackgroundImage="/content/email/images/mail-header-bg.jpg";
            vm.lnf.bodyTextColor="#444444";
            vm.lnf.bodyBackgroundColor="#ffffff";
            vm.lnf.bodyFontFamily="Helvetica,Arial,sans-serif";
            vm.lnf.bodyTitleFontSize=18;
            vm.lnf.bodyMsgFontSize=14;
            vm.lnf.bodyReservationDetailsImage="/content/email/images/visit-details.jpg";
            vm.lnf.bodyCancelImage="/content/email/images/visit-cancel.jpg";
            vm.lnf.bodyContactImage="/content/email/images/contact-us.jpg";
            vm.lnf.bodyFeedbackImage="/content/email/images/feedback.jpg";

            // important since old values would stay, and upload would not be triggered if the user
            // selected the exact same image
            document.getElementById("headerBkgImage").value = "";
            document.getElementById("resDetailsImage").value = "";
            document.getElementById("cancelImage").value = "";
            document.getElementById("feedbackImage").value = "";
            document.getElementById("contactImage").value = "";

            // triggers image refresh on the live preview
            vm.imageCacheBuster = new Date().getTime();
        }

        $scope.availableTimesForReservationReminder = [
            {time: "1 hour", timeForDB: 1},
            {time: "2 hours", timeForDB: 2},
            {time: "3 hours", timeForDB: 3},
            {time: "4 hours", timeForDB: 4},
            {time: "5 hours", timeForDB: 5},
            {time: "6 hours", timeForDB: 6},
            {time: "7 hours", timeForDB: 7},
            {time: "8 hours", timeForDB: 8},
            {time: "12 hours", timeForDB: 12},
            {time: "1 day", timeForDB: 24},
            {time: "2 days", timeForDB: 48}
        ];

        $scope.verificationCondition = [
            {text: "Always send verification email.", value: 1},
            {text: "Send verification email only if customer email is not verified before.", value: 2},
            {text: "Never send verification email.", value: 3}
        ]

        vm.isFree = vm.subscription=='FREE';
        vm.isStandard = vm.subscription=='STANDARD';
        vm.isProfessional = vm.subscription=='PROFESSIONAL';
        $scope.reminderChannels = [
            {text: "E-mail only.", value: "EMAIL"}
        ]
        if(!vm.isFree) {
            $scope.reminderChannels.push({text: "Text message if phone defined, otherwise via e-mail.", value: "SMS"});
        }
        $scope.confirmationChannels = [
            {text: "E-mail only.", value: "EMAIL"}
        ]
        if(!vm.isFree) {
            $scope.confirmationChannels.push({text: "Text message if phone defined, otherwise via e-mail.", value: "SMS"});
        }
        $scope.closeReservationVerificationDialog = function() {
            vm.reservationVerificationPreview.dismiss('cancel');
        }

        vm.reminderSubscribeText = function() {
            if (vm.isFree) {
                return "Upgrade to PAY_AS_YOU_GO, STANDARD to enable text or to PROFESSIONAL subscription to enable voice reminders as well.";
            }
        }

        vm.confirmationSubscribeText = function() {
            if(vm.isFree) {
                return "Upgrade to PAY_AS_YOU_GO, STANDARD to enable text or to PROFESSIONAL subscription to enable voice confirmation messages as well.";
            }
        }

        $scope.showReservationVerificationDialog = function() {
            vm.reservationVerificationPreview = $uibModal.open({
                templateUrl: 'app/settings/notification/reservation.verification.preview.html',
                scope: $scope
            });
        }

        $scope.closeReservationConfirmationDialog = function() {
            vm.reservationConfirmationPreview.dismiss('cancel');
        }

        $scope.showReservationConfirmDialog = function() {
            vm.reservationConfirmationPreview = $uibModal.open({
                templateUrl: 'app/settings/notification/reservation.confirm.preview.html',
                scope: $scope
            });
        }

        $scope.closeReservationReminderDialog = function() {
            vm.reservationReminderPreview.dismiss('cancel');
        }

        $scope.showReservationReminderDialog = function() {
            vm.reservationReminderPreview = $uibModal.open({
                templateUrl: 'app/settings/notification/reservation.reminder.preview.html',
                scope: $scope
            });
        }

        $scope.closeReservationCancelDialog = function() {
            vm.reservationCancelPreview.dismiss('cancel');
        }

        $scope.showReservationCancelDialog = function() {
            vm.reservationCancelPreview = $uibModal.open({
                templateUrl: 'app/settings/notification/reservation.cancel.preview.html',
                scope: $scope
            });
        }

        $scope.closeReservationUpdateDialog = function() {
            vm.reservationUpdatePreview.dismiss('cancel');
        }

        $scope.showReservationUpdateDialog = function() {
            vm.reservationUpdatePreview = $uibModal.open({
                templateUrl: 'app/settings/notification/reservation.update.preview.html',
                scope: $scope
            });
        }


        $scope.closeReservationThankYouDialog = function() {
            vm.reservationThankYouPreview.dismiss('cancel');
        }

        $scope.showReservationThankYouDialog = function() {
            vm.reservationThankYouPreview = $uibModal.open({
                templateUrl: 'app/settings/notification/reservation.thankyou.preview.html',
                scope: $scope
            });
        }

        $scope.closeWaitlistAddToDialog = function() {
            vm.waitlistAddToDialog.dismiss('cancel');
        }

        $scope.showWaitlistAddToDialog = function() {
            vm.waitlistAddToDialog = $uibModal.open({
                templateUrl: 'app/settings/notification/waitlist.addto.preview.html',
                scope: $scope
            });
        }

        $scope.closeWaitlistNotifyDialog = function() {
            vm.waitlistNotifyDialog.dismiss('cancel');
        }

        $scope.showWaitlistNotifyDialog = function() {
            vm.waitlistNotifyDialog = $uibModal.open({
                templateUrl: 'app/settings/notification/waitlist.notify.preview.html',
                scope: $scope
            });
        }

        $scope.closeWaitlistThankYouDialog = function() {
            vm.waitlistThankYouDialog.dismiss('cancel');
        }

        $scope.showWaitlistThankYouDialog = function() {
            vm.waitlistThankYouDialog = $uibModal.open({
                templateUrl: 'app/settings/notification/waitlist.thankyou.preview.html',
                scope: $scope
            });
        }

        $scope.getDate = function(offset) {
            var monthNames = ["January", "February", "March", "April", "May", "June",
                "July", "August", "September", "October", "November", "December"
            ];
            var date = new Date(Date.now() + (offset * 24 * 60 * 60 * 1000));
            return monthNames[date.getMonth()] + " " + date.getDate() + ", " + date.getFullYear();
        }

        $scope.getLocationName = function() {
            return ApplicationContext.getLocation().name;
        }

        $scope.getLocationAddress = function() {

            var fullAddress =
                ((ApplicationContext.getLocation().address1==null)?"":ApplicationContext.getLocation().address1)
                + ((ApplicationContext.getLocation().address2==null)?"":" " + ApplicationContext.getLocation().address2)
                + ((ApplicationContext.getLocation().address3==null)?"":" " + ApplicationContext.getLocation().address3) + ", "
                + ((ApplicationContext.getLocation().zipcode==null)?"":ApplicationContext.getLocation().zipcode) + " "
                + ((ApplicationContext.getLocation().city==null)?"":ApplicationContext.getLocation().city) + ", "
                + ((ApplicationContext.getLocation().countryState==null)?"":ApplicationContext.getLocation().countryState) + ", "
                + ((ApplicationContext.getLocation().country==null)?"":ApplicationContext.getLocation().country);

            return fullAddress;
        }

        $scope.getContactPhoneNumber = function() {
            return (ApplicationContext.getLocation().phone==null?"":ApplicationContext.getLocation().phone);
        }

        $scope.getContactEmail = function() {
            return (ApplicationContext.getLocation().email==null?"":ApplicationContext.getLocation().email);
        }

        $scope.fileSelected = function(elementId, type) {
            console.log('File selected: ' + elementId + " - " + type);
        }

        $scope.upload = function(elementId, type) {
            var file = document.getElementById(elementId).files[0];
            var size = file.size;
            if (size > 512000) {
                SweetAlert.swal("File too large", "Maximum allowed file size is 500 kB.", "error");
                return;
            }

            var r = new FileReader();
            r.onloadend = function(e) {
                var formData = new FormData();
                formData.append('file', file);

                $http.post(vm.uploadUrl + '?type=' + type, formData, {
                        transformRequest: angular.identity,
                        headers: {'Content-Type': undefined}
                })
                .success(function(data){
                    vm.imageCacheBuster = new Date().getTime(); // triggers refresh
                    if (type === 'HEADER_BKG') {
                        vm.lnf.headerBackgroundImage = data.url;
                    } else if (type === 'RES_DETAILS') {
                        vm.lnf.bodyReservationDetailsImage = data.url;
                    } else if (type === 'CANCEL') {
                        vm.lnf.bodyCancelImage = data.url;
                    } else if (type === 'CONTACT') {
                        vm.lnf.bodyContactImage = data.url;
                    } else if (type === 'FEEDBACK') {
                        vm.lnf.bodyFeedbackImage = data.url;
                    }
                })
                .error(function(){
                    SweetAlert.swal("Error during upload", "Please contact support support@seaton site for help.", "error");
                });
            }
            r.readAsArrayBuffer(file);
        }
    }
})();



