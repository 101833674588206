(function () {
    'use strict';

    angular
        .module('seatonApp')
        .controller('SetupSuccessController', SetupSuccessController);

    SetupSuccessController.$inject = ['$scope', '$state', '$stateParams', 'Log', 'PublicResource'];

    function SetupSuccessController($scope, $state, $stateParams, Log, PublicResource) {

        var vm = this;

    }
})();



