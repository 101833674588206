'use strict';

(function () {
    var module = angular.module('seatonApp');

    module.factory('EventResource', eventResource);

    eventResource.$inject = ['$resource', 'ApplicationContext'];
    function eventResource($resource, ApplicationContext) {
        return $resource('api/v1/merchants/_MERCHANTID_/events/:id', {}, {
            'get': { //TBD implement server side
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            }, 'getHomeDashboardTodayData': {
                method: 'GET',
                url: 'api/v1/merchants/_MERCHANTID_/events/statistics/home/today',
                isArray: false
            }, 'getHomeWeekStats': {
                method: 'GET',
                url: 'api/v1/merchants/_MERCHANTID_/events/statistics/home/weekStats',
                isArray: false
            }, 'getHomeDashboardTodayVsData': {
                method: 'GET',
                url: 'api/v1/merchants/_MERCHANTID_/events/statistics/home/compareToday',
                isArray: false
            }, 'getEventsDashboardTodayData': {
                method: 'GET',
                url: 'api/v1/merchants/_MERCHANTID_/events/statistics/dashboard/today',
                isArray: false
            }, 'getEventsDashboardTodayVsData': {
                method: 'GET',
                url: 'api/v1/merchants/_MERCHANTID_/events/statistics/dashboard/compareToday',
                isArray: false
            }, 'getEventsOverview': {
                method: 'GET',
                url: 'api/v1/merchants/_MERCHANTID_/events/statistics/overview/reservations',
                isArray: false
            }, 'getEmployeeLastActivity': {
                method: 'GET',
                url: 'api/v1/merchants/_MERCHANTID_/employees/history',  //TODO move to employe.service.js
                isArray: true
            }, 'overview': {
                method: 'GET',
                url: 'api/v1/merchants/_MERCHANTID_/events/overview',
                isArray: true
            }
        });
    }

})();
