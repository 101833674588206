(function() {
    'use strict';
    angular
        .module('seatonApp')
        .factory('MigrationService', MigrationService);

    MigrationService.$inject = ['$resource'];

    function MigrationService($resource) {
        var resourceUrl = 'api/auth/admin/migrate';

        return $resource(resourceUrl, {}, {
            'migrateOneTimeSlot': {method: 'GET', url: 'api/admin/migration/oneTimeSlot/:locationId'},
            'migrateAllTimeSlot': { method:'GET', url: 'api/admin/migration/allTimeSlot', isArray: true}
        });
    }
})();
