(function () {
    'use strict';

    angular
        .module('seatonApp')
        .controller('ReservationBlockController', ReservationBlockController);

    ReservationBlockController.$inject = ['$scope', '$state', 'SettingsResource', 'Log', '$location', '$uibModal', '$document',
                                'ApplicationContext', 'ListUtils', 'SweetAlert', 'Utils'];

    var DATE_FORMAT = 'YYYY-MM-DD';
    var TIME_SERVER_FORMAT = 'HH:mm:ss.SSS';
    var TIME_DISPLAY_FORMAT = 'hh:mmA';
    var DATE_PICKER_FORMAT = 'MMM D, YYYY';

    function ReservationBlockController($scope, $state, SettingsResource, Log, $location, $uibModal, $document,
                               ApplicationContext, ListUtils, SweetAlert, Utils) {

        var vm = this;
        reset();

        SettingsResource.getBlockEvents({}, onSuccessListUpdate, onError);

        // initialize date & time picker
        $document.ready(
            function () {
                // date picker
                var today = new Date();
                var todayWithLocale = moment(today, "YYYY-MM-DD").toDate();
                vm.selectedDay = todayWithLocale;
                vm.datePickerSection = $document.find('input[name="datePickerSection"]');
                vm.datePickerSection.daterangepicker(
                    {
                        singleDatePicker: true,
                        showDropdowns: true,
                        locale: {format: DATE_PICKER_FORMAT},
                        minDate: todayWithLocale
                    },
                    function (start, end, label) {
                        vm.selectedDay = start.format(DATE_FORMAT);
                        vm.selectedDayFormatted = moment(vm.selectedDay, DATE_FORMAT).format(DATE_PICKER_FORMAT);
                    }
                );
                vm.datePickerSection.val(vm.selectedDayFormatted);
            }
        );

        $scope.openDatePickerSection = function () {
            vm.datePickerSection.focus();
        }

        vm.onSection = function() {
            SettingsResource.getAreas({}, onAreaSuccess, onError);

            function onAreaSuccess(data) {
                vm.availableAreas = data;
            }
        }

        vm.onResource = function() {
            SettingsResource.getAreasAndTables({}, onResourceSuccess, onError);

            function onResourceSuccess(data) {
                vm.availableResources = data;
            }
        }


        vm.saveBlockEvent = function() {
            if(checkToBeforeFrom(vm.block.from.hour, vm.block.from.mins, vm.block.from.amPm, vm.block.to.hour, vm.block.to.mins, vm.block.to.amPm)) {
                SweetAlert.swal({
                        html: true,
                        title: "Time not defined properly.",
                        text: "<strong>To</strong> time same or before <strong>from</strong> time.",
                        type: "error",
                        showCancelButton: false,
                        confirmButtonColor: "#DD6B55", confirmButtonText: "Ok",
                        closeOnConfirm: true
                    }
                );
                return;
            }

            var titleText = ' <b>Block summary.</b>';
            var htmlMessage =
                "<span class='fa fa-calendar'> &nbsp;&nbsp;</span><strong>" + vm.selectedDayFormatted + "</strong><br/>" +
                "<span class='fa fa-clock-o'> &nbsp;&nbsp;</span><strong>" + vm.block.from.hour + ":" + vm.block.from.mins + " " + vm.block.from.amPm + " -> " + vm.block.to.hour + ":" + vm.block.to.mins + " " + vm.block.to.amPm + "</strong><br/>" +
                "<strong>" + selectedListText() + "</strong><br/>" +
                "" + scopeText(vm.block.scope) + "<br/>";
            SweetAlert.swal({
                    html: true,
                    title: titleText,
                    text: htmlMessage,
                    type: "info",
                    showCancelButton: true,
                    confirmButtonColor: "#1db394", confirmButtonText: "Save",
                    cancelButtonText: "Cancel",
                    closeOnConfirm: true,
                    closeOnCancel: true
                },
                function (isConfirm) {
                    if(isConfirm) {

                        var startTime = moment(vm.selectedDayFormatted + " " + vm.block.from.hour + ":" + vm.block.from.mins +  vm.block.from.amPm, "MMM D, YYYY hh:mmA").toDate();
                        var endTime = moment(vm.selectedDayFormatted + " " + vm.block.to.hour + ":" + vm.block.to.mins +  vm.block.to.amPm, "MMM D, YYYY hh:mmA").toDate();
                        var data = {
                            startTime: startTime,
                            endTime: endTime,
                            type: vm.block.type,
                            scope: vm.block.scope,
                            description: vm.block.description,
                            listText: selectedListText()
                        };
                        if(vm.block.type=="SECTION") {
                            data.areas = getSelectedIds();
                        } else {
                            data.resources = getSelectedIds();
                        }
                        SettingsResource.saveBlockEvent(data, onSuccessListUpdate, onError);
                    }
                }
            );
        }

        vm.deleteBlockEvent= function(blockId) {
            SweetAlert.swal({
                    html: true,
                    title: "Do you want to delete block event?" ,
                    text: "",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: "Delete",
                    cancelButtonText: "Cancel",
                    closeOnConfirm: true,
                    closeOnCancel: true
                },
                function (isConfirm) {
                    if (isConfirm) {
                        SettingsResource.deleteBlockEvent({blockEventId: blockId}, onSuccessListUpdate, onError);
                    }
                });
        }


        function onSuccessListUpdate(data) {
            reset();
            vm.blockEvents = data;
        }

        function onError(error) {
            console.log("Error "+error);
        }


    // checks and utility functions
        function scopeText(scope) {
            if(scope=="ALL") return "All reservations (online & offline)";
            else return "Only for online reservations";
        }

        vm.listResourceOrSection = function(block) {
            return block.listText;
        }

        $scope.blockScope = [
            {value: "ALL", name: "All reservations (online & offline)"},
            {value: "ONLINE_ONLY", name: "Only for online reservations"}
        ]

        function checkToBeforeFrom(fromHours, fromMins, fromAmPm, toHours, toMins, toAmPm) {

            var from = moment(fromHours + ":" + fromMins + fromAmPm, "hh:mmA");
            var to = moment(toHours + ":" + toMins + toAmPm, "hh:mmA");

            if(to.isBefore(from)) return true;
            if(from.isSame(to)) return true;
            return false;

        }

        vm.openLink = function(to) {
            if(to=='NON') {
                $state.go('settings.worktime');
            } else if (to == 'SPECIAL') {
                $state.go('settings.worktime');
            } else if(to == 'DISABLE') {
                $state.go('settings.online.reservation.settings');
            }
        }

        vm.inputOk = function(type) {
            if(!vm.selectedDayFormatted) return false;
            if(!vm.block.from.hour || !vm.block.from.mins || !vm.block.from.amPm) return false;
            if(!vm.block.to.hour || !vm.block.to.mins || !vm.block.to.amPm) return false;
            if(!vm.block.scope) return false;

            var check = false;
            if(type=='SECTION') {
                if (vm.availableAreas) {
                    for (var i = 0; i < vm.availableAreas.length; i++) {
                        if (vm.availableAreas[i].selected === true) {
                            check = true;
                        }
                    }
                }
            } else if(type=='RESOURCE') {
                if (vm.availableResources) {
                    for (var i = 0; i < vm.availableResources.length; i++) {
                        if(vm.availableResources[i].resources) {
                            for (var j = 0; j < vm.availableResources[i].resources.length; j++) {
                                if (vm.availableResources[i].resources[j].selected === true) {
                                    check = true;
                                }
                            }
                        }
                    }
                }
            }
            if(!check) return check;
            return true;
        }

        function getSelectedIds() {
            var selectedIds = [];
            if (vm.block.type == 'SECTION') {
                if (vm.availableAreas) {
                    for (var i = 0; i < vm.availableAreas.length; i++) {
                        if (vm.availableAreas[i].selected === true) {
                            selectedIds.push(vm.availableAreas[i]);
                        }
                    }
                }
            } else if(vm.block.type == 'RESOURCE') {
                if(vm.availableResources) {
                    for(var i = 0; i < vm.availableResources.length; i++) {
                        for (var j = 0; j < vm.availableResources[i].resources.length; j++) {
                            if (vm.availableResources[i].resources[j].selected === true) {
                                selectedIds.push(vm.availableResources[i].resources[j]);
                            }
                        }
                    }

                }
            }
            console.log("Selected:" + JSON.stringify(selectedIds));
            return selectedIds;
        }


        function selectedListText() {
            var text = "";
            var first = true;
            if (vm.block.type == 'SECTION') {
                if (vm.availableAreas) {
                    for (var i = 0; i < vm.availableAreas.length; i++) {
                        if (vm.availableAreas[i].selected === true) {
                            if (first)text += vm.availableAreas[i].name;
                            else text += ", " + vm.availableAreas[i].name;
                            first = false;
                        }
                    }
                }
            } else if(vm.block.type == 'RESOURCE') {
                if(vm.availableResources) {
                    for(var i = 0; i < vm.availableResources.length; i++) {
                        for (var j = 0; j < vm.availableResources[i].resources.length; j++) {
                            if (vm.availableResources[i].resources[j].selected === true) {
                                if (first)text += vm.availableResources[i].resources[j].name;
                                else text += ", " + vm.availableResources[i].resources[j].name;
                                first = false;
                            }
                        }
                    }
                }
            }
            if(text=="")text="<i>None</i>";
            return text;

        }

        vm.formatDate = function(date) {
            return moment(date).format(DATE_FORMAT);
        }

        vm.formatTime = function(date) {
            return moment(date).format( TIME_DISPLAY_FORMAT);
        }

        vm.isActive = function(end) {
            var now = moment();
            if(now.isBefore(moment(end))) return true;
            return false;
        }

        function reset() {
            vm.block = {};
            vm.block.scope="ONLINE_ONLY";
            vm.block.from = {};
            vm.block.to = {};
            vm.availableAreas = {};
        }


    }
})();



