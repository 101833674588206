    (function () {
    'use strict';
    angular.module('seatonApp').controller('OnlineCreateLegacyController', OnlineCreateLegacyController);

    OnlineCreateLegacyController.$inject = ['$rootScope', '$scope', '$state', '$document', '$stateParams', 'Log', 'PublicResource',
                                            'OnlineReservationContext'];

    function OnlineCreateLegacyController($rootScope, $scope, $state, $document, $stateParams, Log, PublicResource,
                                          OnlineReservationContext) {

    // INITIALIZE
        var vm = this;

        vm.DATE_FORMAT = 'YYYY-MM-DD';
        vm.DATE_PICKER_FORMAT = 'MMM D, YYYY';
        vm.TIME_SERVER_FORMAT = 'HH:mm:ss.SSS';
        vm.TIME_DISPLAY_FORMAT = 'hh:mmA';
        vm.moment = moment;

        vm.inputErrorOccured = false;
        vm.firstPage = true;
        vm.actionSubmitted = false;
        // when set, we ignore params from the query string (?date=...&party_size&....)
        vm.ignoreInputFromQueryParams = false;

        vm.customer = null;
        vm.resourceNotAvailableAnymore = false;
        vm.productChosenByGuest = false;
        vm.productList = [];

        // function definition
        vm.onDateChange = onDateChange;
        vm.onTimeChange = onTimeChange;
        vm.resetFirstPage = resetFirstPage;
        vm.findTable = findTable;
        vm.selectAlternativeSlot = selectAlternativeSlot;
        vm.inputErrorOccurs = inputErrorOccurs;
        vm.initPicker = initPicker;
        vm.openDatePicker = openDatePicker;
        vm.reformatPhone = reformatPhone;
        vm.findProductById = findProductById;
        vm.filterByParams = filterByParams;

    // PARAMETERS AND SETTINGS
        vm.locationId = OnlineReservationContext.getLocationId();
        Log.debug("OnlineCreateLegacyController", "Hashed locationId id is '" + vm.locationId + "'");
        if (!vm.locationId) {
            // if location id not return to reservation proxy
            $state.go("public.online.reservation");
        } else {
            // if location id defined proceed with initialization
            fetchReservationSettings();
        }

        // GET reservation settings: alternative times, add to wait list, maximum party size, note to customer, etc...
        function fetchReservationSettings() {

            PublicResource.fetchReservationSettings(
                {
                    hashedLocationId: vm.locationId
                },
                function (data) {
                    vm.settings = data;

                    vm.formattedWebPageLink = addHttpPrefix(vm.settings.businessWebPage); // adds http: prefix if missing

                    OnlineReservationContext.storeSettings(vm.settings);
                    if(!vm.settings.minSeats) vm.settings.minSeats=1;
                    vm.persons = [];
                    for (var i = vm.settings.minSeats; i <= vm.settings.maxSeats; i++) {
                        if (i == 1) {
                            vm.persons.push({"id": 1, "label": "1 person"});
                        } else {
                            vm.persons.push({"id": i, "label": "" + i + " persons"});
                        }
                    }

                    vm.phoneRegion = data.phoneRegion;
                    vm.phonePrefix = data.phonePrefix;
                    vm.productChosenByGuest = data.productChosenByGuest;

                    var localeApp = 'en';
                    if (vm.phoneRegion == 'US') {
                        localeApp = 'en';
                        $rootScope.currencyIcon = 'fa fa-dollar';
                    } else if (vm.phoneRegion == 'GB') {
                        localeApp = 'en-gb';
                        $rootScope.currencyIcon = 'fa fa-gbp';
                    } else if (vm.phoneRegion == 'IE') {
                        localeApp = 'en-ie';
                        $rootScope.currencyIcon = 'fa fa-euro';
                    } else if (vm.phoneRegion == 'CA') {
                        localeApp = 'en-ca';
                        $rootScope.currencyIcon = 'fa fa-money';
                    }

                    moment.locale(localeApp);
                    Log.debug("OnlineCreateLegacyController","locale:" + moment.locale() + " firstDay:" + moment.localeData().firstDayOfWeek());


                    vm.request = OnlineReservationContext.getRequest(); // reservation request -> date, time & party_size, productId
                    vm.channel = OnlineReservationContext.getChannel(); // channel
                    vm.reference = OnlineReservationContext.getReference(); // reference PROXY, CONTACT, PAYMENT

                    if(!vm.channel) {
                        $state.go("public.online.reservation");
                        return;
                    }

                    vm.selectedDay = vm.request.date;
                    vm.selectedDayFormatted = moment(vm.selectedDay, vm.DATE_FORMAT).format(vm.DATE_PICKER_FORMAT);
                    if(vm.request.party_size) {
                        vm.numberOfPersons = vm.request.party_size;
                    }

                    vm.selectedTime = vm.request.time;
                    if (vm.settings && vm.settings.productChosenByGuest) {
                        vm.durationTime = vm.request.duration;
                    }

                    if (vm.reference == 'PAYMENT' || vm.reference == 'CONTACT') {
                        // reference is contact or payment page, reschedule action triggered
                        vm.onDateChange();

                    } else if(vm.reference == 'PROXY'){
                        if (isReservationRequestValid(vm.request)) {
                            vm.findTable();
                        } else {
                            vm.resetFirstPage();
                        }
                    } else {
                        vm.resetFirstPage();
                    }

                    $document.ready(
                        function () {
                            vm.initPicker();
                        }
                    );

                },
                onError
            );
        };

        function isReservationRequestValid(request) {
            if(request) {
                Log.debug("OnlineCreateLegacyController","Query params, date:"+request.date+", " +
                    "time:"+request.time+", party_size:"+request.party_size+".");
            }
            if (vm.settings && vm.settings.productChosenByGuest) {
                Log.debug("Additionally check duration, duration is '"+vm.durationTime+"'.");
                if(!vm.durationTime) return false;
            }
            return request && request.date && request.time && request.party_size;
        }

    // FIRST PAGE

        // on selected date change call this function
        function onDateChange () {

            vm.findTableData = null;
            if (moment(vm.selectedDay, vm.DATE_FORMAT).isBefore(moment().startOf('day'))) {
                vm.isDateBeforeToday = true;
                vm.times = [];
                vm.selectedTime = null;
                vm.selectedTimeId = "";
                return;
            } else {
                vm.isDateBeforeToday = false;
            }

            if (moment(vm.selectedDay, vm.DATE_FORMAT).isAfter(moment(vm.settings.maxReservationDate).startOf('day'))) {
                vm.isAfterMaxReservationDate = true;
                vm.times = [];
                vm.selectedTime = null;
                vm.selectedTimeId = "";
                return;
            } else {
                 vm.isAfterMaxReservationDate = false;
            }

            PublicResource.fetchReservationHourList({
                hashedLocationId: vm.locationId,
                requestedDate: vm.selectedDay
            }, onSuccess, onError);

            function onSuccess(data, headers) {
                vm.hoursListData = data.hourList;
                vm.status = data.status;
                vm.addText = data.addText;
                vm.times = [];
                if (vm.status == 0) {
                    for (var i = 0; i < vm.hoursListData.length; i++) {
                        vm.times.push({
                            "id": i,
                            "label": moment(vm.hoursListData[i].time, vm.TIME_SERVER_FORMAT).format(vm.TIME_DISPLAY_FORMAT),
                            "limit": vm.hoursListData[i].limit,
                            "actualAmount": vm.hoursListData[i].actualAmount
                        });
                    }
                    vm.selectedTime = null;
                    vm.selectedTimeId = "";
                } else {
                    vm.selectedTime = null;
                    vm.selectedTimeId = "";
                }
                vm.productList = [];
            }
        };

        // on selected time change call this function
        function onTimeChange () {
            vm.selectedTime = vm.hoursListData[vm.selectedTimeId].time;
            vm.resourceNotAvailableAnymore = false;

            vm.findTableData = null;

            Log.debug("OnlineCreateLegacyController", "Selected time is '" + vm.selectedTime + "'");
            if(!vm.selectedTime || vm.productChosenByGuest==false) {
                vm.productList = [];
                return;
            } // if product not chosen by guest no need to fetch product list

            //if product not chosen by guest fetch product list from server
            PublicResource.fetchProductList({
                hashedLocationId: vm.locationId,
                requestedDate: vm.selectedDay,
                requestedTime: vm.selectedTime
            }, onSuccess, onError);

            function onSuccess(data, headers) {
                vm.productList = data.productList;
            }
        };

        // find table based on dedfined criteria
        function findTable () {

            vm.actionSubmitted = true;
            vm.resourceNotAvailableAnymore = false;
            vm.inputErrorOccured = false;

            if (!vm.selectedTime || vm.selectedTime == null) { // if time not selected report error
                vm.inputErrorOccured = true;
                vm.actionSubmitted = false;
                return;
            }

            vm.product = null; // if duration null server choose duration
            if (vm.settings.productChosenByGuest) {
                // product chosen by guest
                vm.product = findProductById(vm.selectedProductId);
                Log.debug("OnlineCreateLegacyController", "Product found:"+JSON.stringify(vm.product));
                if(!vm.product) { // product not found report error
                    vm.actionSubmitted = false;
                    vm.inputErrorOccured = true;
                    return;
                }
            }

            PublicResource.findTable(
                {
                    hashedLocationId: vm.locationId
                },
                {
                    requestedDate: vm.selectedDay,
                    requestedTime: vm.selectedTime,
                    numberOfPersons: vm.numberOfPersons,
                    product: vm.product // if product null servers will decide
                },
                function (data) {
                    vm.actionSubmitted = false;
                    vm.findTableData = data;
                    if (vm.findTableData.tableFound) {
                        proceedToContactPage();
                    }
                },
                onError
            );
        };

        // if preffered time not available select alternative time slot
        function selectAlternativeSlot (timeSlot) {
            vm.selectedTime = timeSlot;
            proceedToContactPage();
        };

        function proceedToContactPage() {

            var request = {
                'date': vm.selectedDay,
                'time': vm.selectedTime,
                'party_size': vm.numberOfPersons,
                'product': vm.product
            }
            OnlineReservationContext.storeRequest(request);
            OnlineReservationContext.storeReference("LEGACY");

            $state.go("public.online.contact");
        }

        // reset first page form
        function resetFirstPage () {

            vm.selectedDay = moment().format(vm.DATE_FORMAT); // today, always by default today will be choose as reservation date
            vm.selectedDayFormatted = moment(vm.selectedDay, vm.DATE_FORMAT).format(vm.DATE_PICKER_FORMAT); // format to match date picker
            if(vm.settings && vm.settings.minSeats>2) {
                vm.numberOfPersons = vm.settings.minSeats;
            } else {
                vm.numberOfPersons = 2; // default 2 covers if not defined by minSets
            }

            vm.selectedProductId = null; // if product null server will decide about reservation product
            vm.times = []; // list if reservation tiem slots avilable for reservation
            vm.findTableData = null; // result of find table action

            vm.onDateChange(); // featch reservation time slot available, since due to reset reservation date can be chenged to current
            vm.actionSubmitted = false;
        };

        function filterByParams(timeSlot) {
            if (timeSlot.limit == -1 ||  // max allowed  per time slot not activated or
                timeSlot.limit > timeSlot.actualAmount) { //  actual number equal or higher then maximum allowed
                return true;
            }
            return false;
        }



        // HANDLE ERRORS

        function inputErrorOccurs () {
            return vm.inputErrorOccured;
        };

        function onError(error) {
            Log.debug("OnlineCreateLegacyController", "Unexpected error:" + error + ".");
            vm.actionSubmitted = false;

            OnlineReservationContext.storeReference("LEGACY");

            $state.go("public.online.error");
        };


    // UTILS

        // initialize date & time picker
        function initPicker () {
            $(function () {
                // date picker
                vm.datePicker = $document.find('input[name="datePicker"]');
                vm.datePicker.daterangepicker(
                    {
                        singleDatePicker: true,
                        showDropdowns: true,
                        locale: {format: vm.DATE_PICKER_FORMAT}
                    },
                    function (start, end, label) {
                        vm.selectedDay = start.format(vm.DATE_FORMAT);
                        vm.onDateChange();
                    }
                );
                vm.datePicker.val(vm.selectedDayFormatted);
            });
        };

        // focus date picker on open dialog
        function openDatePicker () {
            vm.datePicker.focus();
        };

        // format phone number
        function reformatPhone () {
            $scope.numInput = $document.find('#primaryPhone');
            if (typeof intlTelInputUtils !== 'undefined') {
                var currentText = $scope.numInput.intlTelInput("getNumber", intlTelInputUtils.numberFormat.INTERNATIONAL);
                if (typeof currentText === 'string') {
                    $scope.numInput.intlTelInput('setNumber', currentText);
                }
            }
        };

        function findProductById(productId) {
            if(vm.productList) {
                for (var i = 0; i < vm.productList.length; i++) {
                    if(vm.productList[i].id == productId)
                        return vm.productList[i];
                }
            }
            // product not found for product id
            return null;
        }

        // if the web site does not start with 'http(s)' this function will add the prefix http://
        function addHttpPrefix(originalLink) {
            if (originalLink) {
                if (originalLink.startsWith('http')) {
                    return originalLink;
                } else {
                    return "http://" + originalLink;
                }
            } else {
                return originalLink;
            }
        }
    }
})();



