'use strict';

ReservationTimelineController.$inject = ['$rootScope', '$state', '$stateParams', '$location', '$scope', '$q', '$filter', '$document', '$timeout',
    'Tables', 'Statistics', 'googleChartApiPromise', 'Reporting', 'ApplicationContext', '$uibModal', '$uibModalStack', 'ChannelService',
    'TextUtil', 'EventResource', 'SettingsResource', 'SweetAlert'];

function ReservationTimelineController($rootScope, $state, $stateParams, $location, $scope, $q, $filter, $document, $timeout,
                                       Tables, Statistics, googleChartApiPromise, Reporting, ApplicationContext, $uibModal, $uibModalStack, ChannelService, TextUtil, EventResource, SettingsResource, SweetAlert) {

    var vm = this;
    vm.coverTrans = TextUtil.translate('person', 'c');
    vm.currentDate = null;
    vm.doUpdate = doUpdate;
    vm.closeNotificationDialog = closeNotificationDialog;
    vm.closeConfirmationDialog = closeConfirmationDialog;
    vm.notificationMessageText = null;
    vm.notificationDialog = null;
    vm.confirmationDialog = null;
    vm.scrollPosition = null;

    vm.displayNotifictionMessageDialog = displayNotifictionMessageDialog;

    var DBG = false;

    $scope.openingHourNotDefined = function () {
        return ApplicationContext.isBusinessHoursNotDefined();
    };

    var ranges_day = {
        'today': {
            from: moment().startOf('day').toISOString(),
            to: moment().endOf('day').toISOString(),
            label: 'Today'
        },
        'tomorrow': {
            from: moment().add(1, 'days').startOf('day').toISOString(),
            to: moment().add(1, 'days').endOf('day').toISOString(),
            label: 'Tomorrow'
        },
        'todayplus2': {
            from: moment().add(2, 'days').startOf('day').toISOString(),
            to: moment().add(2, 'days').endOf('day').toISOString(),
            label: moment().add(2, 'days').format('dddd')
        },
        'todayplus3': {
            from: moment().add(3, 'days').startOf('day').toISOString(),
            to: moment().add(3, 'days').endOf('day').toISOString(),
            label: moment().add(3, 'days').format('dddd')
        },
        'todayplus4': {
            from: moment().add(4, 'days').startOf('day').toISOString(),
            to: moment().add(4, 'days').endOf('day').toISOString(),
            label: moment().add(4, 'days').format('dddd')
        },
        'todayplus5': {
            from: moment().add(5, 'days').startOf('day').toISOString(),
            to: moment().add(5, 'days').endOf('day').toISOString(),
            label: moment().add(5, 'days').format('dddd')
        },
        'todayplus6': {
            from: moment().add(6, 'days').startOf('day').toISOString(),
            to: moment().add(6, 'days').endOf('day').toISOString(),
            label: moment().add(6, 'days').format('dddd')
        }
    };

    SettingsResource.getAreas({},
        function (data) {
            vm.areas = data;
            if (DBG) console.log("vm.areas");
            if (DBG) console.log(vm.areas);
            vm.reload();
        }
    );

    function doUpdate() {
        vm.updateParams["customNotificationText"] = vm.notificationMessageText;
        Tables.updateTimelineEvent(
            vm.updateParams,
            function (data) {
                SweetAlert.swal("Updated!", "", "success");
                if (vm.timelineView) vm.scrollPosition = vm.timelineView.getScrollPosition(); // { left: 0, top: 0 }
                vm.reload();
                //vm.timelineView.scrollTo(vm.scrollPosition);  //comment to reset position to start on day change
                //$state.go('tables.eventDetails', {eventId: data.id});
            },
            function (data) {
                SweetAlert.swal("Change not performed as resources are not available or event is closed.", "", "error");
                return false;
            }
        );
        vm.closeNotificationDialog();
        vm.closeConfirmationDialog()
    }


    function displayNotifictionMessageDialog() {
        vm.closeConfirmationDialog();
        var ev = vm.ev;
        if (ev.seaton_id) {
            var params = {
                id: ev.seaton_id,
                time_new: vm.new_start_time,
                time_old: vm.initial_start_time,
                resource_id_new: vm.new_resource_id,
                resource_id_old: vm.original.seaton_resource_id,
                changeTime: vm.changeTime,
                keepOld: vm.keepOld,
                addNew: vm.addNew
            }
            vm.updateParams = params;

            if (vm.changeTime && (vm.initial_start_time != vm.new_start_time)) {
                //check for update message if there was change of time
                //Log.debug("Fetching event details with id " + vm.eventId + "from database.")
                Tables.getEventDetails(
                    {
                        id: ev.seaton_id,
                    },
                    function (data) {
                        vm.event = data;
                        // need to check if personalization is enabled for location
                        Tables.personalized(
                            {
                                messageType: 'RESERVATION_UPDATE'
                            },
                            function (data) {
                                if (data.enabled) {
                                    vm.notificationMessageText = data.defaultMessage;
                                    vm.closeConfirmationDialog();
                                    vm.notificationDialog = $uibModal.open({
                                        templateUrl: 'app/tables/event/update/notification.message.dialog.html',
                                        scope: $scope,
                                        windowClass: "center-modal"
                                    });
                                } else {
                                    // personalization not enabled
                                    doUpdate();
                                }
                            });
                    }
                );
            } else {
                doUpdate();
            }
        }
    }

    function closeNotificationDialog() {
        if (vm.notificationDialog != null) {
            vm.notificationDialog.close('cancel');
            vm.notificationDialog.dismiss('cancel');
        }
    }

    function closeConfirmationDialog() {
        //$uibModalStack.closeAll();
        $uibModalStack.dismissAll();
        if (vm.confirmationDialog != null) {
            vm.confirmationDialog.close('cancel');
            vm.confirmationDialog.dismiss('cancel');
        }
    }


    vm.getTimelineData = function (period) {
        var range = getRange(period);
        vm.currentDate = moment(range.from);

        if (DBG) console.log(range);
        if (DBG) console.log($location.timezone);

        Tables.timeline(
            {
                date: moment(range.from).format('YYYY-MM-DD')
            },
            function (data) {
                vm.timeline = data;
                var i, j;
                var dhtmlx_sections = []; //example entry: {key: 1, label: "James Smith"}
                var dhtmlx_events = []; //dhtmlx events: {start_date: "2017-06-30 09:00", end_date: "2017-06-30 12:00", text: "Task A-12458", section_id: 1}

                //creating dhtmlx sections - areas
                for (i = 0; i < vm.areas.length; i++) {
                    var area = vm.areas[i];
                    var children = [];
                    //area name truncated due to display issue - if name is too long it is nod displayed at all
                    dhtmlx_sections.push({key: area.id, label: area.name.substring(0, 12), open: true, children: []});
                }


                for (i = 0; i < data.resourceEvents.length; i++) {
                    var resource = data.resourceEvents[i].resource;
                    var events = data.resourceEvents[i].events;
                    if (DBG) console.log(resource);
                    //console.log("events");  console.log(events);
                    //console.log("i=" + i + "  " + data.resourceEvents[i]);

                    //adding table to corresponding section/area
                    _.each(dhtmlx_sections, function (dh_sec) {
                        if (dh_sec.key == resource.areaId) {
                            dh_sec.children.push({
                                key: 'T' + resource.id,
                                label: resource.name,
                                capacity: resource.capacity,
                                color: resource.areaColor
                            });
                        }
                    });

                    //adding events to corresponding section
                    for (j = 0; j < events.length; j++) {
                        if (DBG) console.log(events[j]);
                        var name = events[j].customer;
                        if (name != null) name = events[j].customer.name;
                        if (name == null || name == 'null') name = "Unknown customer";

                        if (DBG) console.log("END TIME" + events[j].endTime);
                        if (DBG) console.log(moment(events[j].endTime));
                        if (DBG) console.log("" + moment(events[j].startTime).format("YYYY-MM-DD HH:mm") + " - " + moment(events[j].endTime).format("YYYY-MM-DD HH:mm"));

                        var type_short = events[j].type.charAt(0);
                        if (events[j].type == "WALK_IN_SEATED") type_short = "S";

                        var numberOfPersons = events[j].numberOfPersons;
                        if (numberOfPersons < 0) numberOfPersons = "B";

                        var element = {
                            start_date: "" + moment(events[j].startTime).format("YYYY-MM-DD HH:mm"),
                            end_date: "" + moment(events[j].endTime).format("YYYY-MM-DD HH:mm"),
                            //text: "<span style=\"background-color:  " + resource.areaColor + " ;\">" + events[j].numberOfPersons + " " + events[j].type.charAt(0) + " " + name + "</span>",
                            text: "",
                            n_of_covers: numberOfPersons,
                            type: type_short,
                            customer_name: name,
                            section_id: 'T' + resource.id,
                            seaton_id: events[j].id,
                            seaton_resource_id: resource.id,
                            seaton_resource_name: resource.name,
                            section_color: resource.areaColor,
                            state: events[j].state,
                            status: events[j].status,
                            note: events[j].note
                        };
                        //element.color = resource.areaColor;  //sets box element color

                        if (DBG) console.log(element);
                        if (DBG) console.log("_____END TIME");
                        dhtmlx_events.push(element);
                    }

                }
                if (DBG) console.log("dhtmlx_sections");
                if (DBG) console.log(dhtmlx_sections);
                if (DBG) console.log("dhtmlx_events");
                if (DBG) console.log(dhtmlx_events);

                $document.ready(
                    function () {

                        var vopt = 1;

                        if (DBG) console.log("scheduler");
                        if (DBG) console.log(scheduler);
                        if (DBG) console.log("scheduler.isViewExists" + scheduler.isViewExists("timeline"));
                        var isTimelineExists = scheduler.isViewExists("timeline");
                        if (isTimelineExists) {
                            //scheduler.render();
                            isTimelineExists = false;
                            scheduler.clearAll();
                        }

                        scheduler.plugins({
                            limit: true,
                            timeline: true,
                            treetimeline: true
                        });
                        //TODO check scheduler.i18n.setLocale("de");

                        //scheduler.resetLightbox();
                        scheduler.locale.labels.timeline_tab = "Timeline";
                        scheduler.locale.labels.section_custom = "Section";
                        scheduler.config.details_on_create = false;
                        scheduler.config.details_on_dblclick = false;
                        scheduler.config.drag_resize = false;
                        //scheduler.config.drag_create = true;

                        scheduler.attachEvent("onBeforeEventCreated", function (ev) {
                            return true;
                        });


                        //handling drag&drop of event
                        scheduler.attachEvent("onBeforeEventChanged", function (ev, e, is_new, original) {
                            console.log(ev);

                            //setting defaults for new events
                            if (ev.text == 'New event') {

                                _.each(dhtmlx_sections, function (dh_area) {
                                    _.each(dh_area.children, function (dh_sec) {
                                        if (dh_sec.key == ev.section_id) vm.new_resource_name = dh_sec.label;
                                    });
                                });

                                $rootScope.timelineNewEvent = ev;
                                $rootScope.timelineNewEvent.new_resource_name = vm.new_resource_name;
                                $state.go('tables.newreservation');
                                return false;
                            }

                            if (typeof original.start_date === 'undefined' || ev.seaton_id == -1 || ev.state == 'CLOSED' || ev.status == 'SHOW') {
                                return false;
                            }

                            vm.new_start_time = ev.start_date.getHours() + ":" + moment(ev.start_date).format("mm");
                            vm.initial_start_time = original.start_date.getHours() + ":" + moment(original.start_date).format("mm");
                            vm.new_resource_id = ("" + ev.section_id).substring(1);
                            vm.new_resource_name = 'NA';

                            _.each(dhtmlx_sections, function (dh_area) {
                                _.each(dh_area.children, function (dh_sec) {
                                    if (dh_sec.key == ev.section_id) vm.new_resource_name = dh_sec.label;
                                });
                            });

                            var titleText = ' <b>Update event? </b>  <br><br>';
                            vm.ev = ev;
                            vm.original = original;
                            var htmlText = titleText + "Update event for " + ev.customer_name + ".<br/><br/> <b>Changes:</b><br/>";
                            var isEventChanged = false;

                            vm.isTimeChanged = false;
                            vm.isSectionChanged = false;
                            vm.changeTime = false;
                            vm.keepOld = false;
                            vm.addNew = true;

                            if (vm.initial_start_time != vm.new_start_time) {
                                vm.isTimeChanged = true;
                                htmlText += "<span class='fa fa-clock-o text-danger'> Reservation time changed from <b>" +
                                    vm.initial_start_time + "</b> to <b>" + vm.new_start_time + "</b></span><br>";
                                isEventChanged = true;
                            }
                            if (ev.section_id != original.section_id) {
                                vm.isSectionChanged = true;
                                htmlText += "<span class='fa fa-dot-circle-o text-danger'> Reservation moved from <b>" +
                                    original.seaton_resource_name + "</b> to <b>" + vm.new_resource_name + "</b>"; //fetch section name from ID
                                isEventChanged = true;
                            }
                            if (!isEventChanged)
                                return false;
                            //" to new time:" + new_start_time + " initial: " + initial_start_time + " resource change:" + ev.section_id + "  initial:" + original.section_id;

                            vm.htmlText = htmlText;
                            vm.confirmationDialog = $uibModal.open({
                                templateUrl: 'app/tables/timeline/timeline.drag.dialog.html',
                                scope: $scope,
                                windowClass: "center-modal"
                            });
                            //vm.closeConfirmationDialog();

                            return false;  //return true to confirm move
                        });

                        //prevent dblClick
                        scheduler.attachEvent("onDblClick", function () {
                            return false;
                        });

                        //add event - onClick go to event details page
                        scheduler.attachEvent("onClick", function (id, e) {
                            //any custom logic here
                            if (typeof id === 'undefined') {
                                return false;
                            }

                            if (DBG) console.log("onClick event:" + id);
                            var dh_ev;
                            _.each(dhtmlx_events, function (dh_ev) {
                                if (DBG) console.log(dh_ev);
                                if (dh_ev.id == id) {
                                    if (DBG) console.log("found seaton_id" + dh_ev.seaton_id);
                                    // if id less than 0. its block, go to block page
                                    if (dh_ev.seaton_id < 0) $state.go("tables.block");
                                    else $state.go("tables.eventDetails", {eventId: dh_ev.seaton_id});
                                }
                            });
                            return false;
                        });

                        scheduler.templates.event_bar_text = function (start, end, ev) {

                            if (typeof ev.type === 'undefined') {
                                ev.type = 'R';
                                ev.n_of_covers = 2;
                            }

                            if (typeof ev.customer_name === 'undefined') {
                                ev.customer_name = 'New';
                            }

                            var eventType = scheduler.serverList("eventType").find(function (type) {
                                if (type.key == ev.type) {
                                    return true;
                                }
                            });

                            if (DBG) console.log("EVENT------------------------------");
                            if (DBG) console.log(ev);

                            var details = [];
                            if (ev.type != "B" && ev.text && ev.text.length) {
                                details.push("<span>?</span>");
                            }
                            if (ev.type != "B" && ev.note) {
                                details.push("<span>!</span>");
                            }

                            var detailsBlock = "";
                            if (details.length) {
                                detailsBlock = "<div class='event-details'>" + details.join("") + "</div>";
                            }

                            var ev_color = 'green'; //ev.section_color
                            if (ev.start_date.valueOf() > currentDate.valueOf()) {
                                ev_color = '#3e8c66';   //ev_color = 'green';
                            }
                            if (ev.start_date.valueOf() < currentDate.valueOf()) {
                                //ev_color = 'red';
                                ev_color = '#e67575';
                            }

                            if (ev.type == 'B') ev_color = '#aaaaaa';   //ev_color = '#aaa';
                            else {
                                if (ev.status == 'SHOW') ev_color = '#7cafe6';   //ev_color = 'blue';
                                if (ev.state == 'CLOSED') ev_color = '#dcdcdc';  //ev_color = '#eee';
                            }

                            var ret_value = "<div class='event-content'>" +
                                "<div style='width: 15px;background:" + ev_color + ";'>" + ev.n_of_covers + "</div>";

                            if (ev.state != 'CLOSED') {
                                if (ev.type == 'R') ret_value += "<div class='event-type_r'><div>" + ev.type + "</div></div>";
                                if (ev.type == 'W') ret_value += "<div class='event-type_w'><div>" + ev.type + "</div></div>";
                                if (ev.type == 'S') ret_value += "<div class='event-type_s'><div>" + ev.type + "</div></div>";
                            } else {
                                ret_value += "<div class='event-type_old'><div>" + ev.type + "</div></div>";
                            }

                            //"<div class='event-type'><div>" + eventType.short + "</div></div>" +
                            //"<div class='event-type'><div>" + ev.type + "</div></div>" +
                            //"<span style='background:blue;'><div class='event-type'><div>" + ev.type + "</div></div></span>" +
                            //"<span><div style='width: 18px;height: 18px;border-radius: 20px;color:white;text-align:center;vertical-align: middle;background:" + ev.section_color + ";'><div>" + ev.type + "</div></div></span>" +
                            ret_value += "<div class='customer-name'>" +
                                ev.customer_name + "</div>" +
                                detailsBlock +
                                "</div>";

                            return ret_value;
                        };

                        var currentMoment = moment();
                        if (DBG) console.log(currentMoment);
                        if (DBG) console.log("moment().hour():"+moment().hour());
                        if (DBG) console.log("moment().format( 'H:mm:ss a') :"+moment().format( 'H:mm:ss a'));

                        var currentDate = new Date(new Date(moment().year(), moment().month(), moment().date(), moment().hour(), moment().minute()));
                        if (DBG) console.log(currentDate);

                        //different display of event depending if it's OPEN or CLOSED
                        scheduler.templates.event_class = function (start, end, event) {
                            //determines
                            if (event.type == 'B') return "block-event";
                            if (event.state == 'OPEN') return "";
                            if (event.state == 'CLOSED') return "old-event";
                            if (event.end_date.valueOf() < currentDate.valueOf()) {
                                return "old-event";
                            }
                            return "";
                        }


                        scheduler.config.now_date = currentDate;
                        scheduler.config.mark_now = true;
                        scheduler.locale.labels.timeline_tab = "Timeline";

                        //===============
                        //Configuration
                        //===============
                        var sections = dhtmlx_sections;
                        scheduler.serverList("tables", dhtmlx_sections);

                        if (vopt == 1)
                            scheduler.createTimelineView({
                                section_autoheight: false,
                                name: "timeline",
                                x_unit: "minute",
                                x_date: "%H:%i",
                                x_step: 15,
                                x_size: 24 * 4,
                                x_start: 0 * 4,
                                x_length: 24 * 60 / 15,
                                y_unit: scheduler.serverList("tables"),
                                y_property: "section_id",
                                render: "tree",
                                scrollable: true,
                                column_width: 50,
                                dx: 140,
                                event_dy: 38,
                                resize_events: false,
                                folder_dy: 44,
                                dy: 44
                            });


                        if (vopt == 2 && !isTimelineExists) scheduler.createTimelineView({
                            section_autoheight: false,
                            name: "timeline",
                            x_unit: "minute",
                            x_date: "%i",
                            x_step: 15,
                            //x_size: 17 * 4,
                            //x_start: 7 * 4,
                            x_size: 24 * 4,
                            x_start: 0 * 4,
                            x_length: 24 * 60 / 15,
                            y_unit: scheduler.serverList("tables"),
                            y_property: "section_id",
                            render: "tree",
                            second_scale: {
                                x_unit: "hour", // unit which should be used for second scale
                                x_date: "%h%a" // date format which should be used for second scale, "July 01"
                            },
                            scrollable: true,
                            column_width: 50,
                            //,
                            //folder_dy:30,
                            //dy:60
                            dx: 130,
                            event_dy: 38,
                            resize_events: false,
                            folder_dy: 44,
                            dy: 44
                        });

                        var hourFormat = scheduler.date.date_to_str("%g%A");

                        //defines HEADER text
                        scheduler.templates.timeline_scale_date = function (date) {
                            //console.log("date");
                            //console.log(date);
                            if (date.getMinutes() == 0) {
                                return hourFormat(date);
                            } else {
                                return date.getMinutes();
                            }
                        };

                        //formats VERTICAL LINES (but not HEADER line!)
                        scheduler.templates.timeline_cell_class = function (evs, date, section) {
                            //sets css for vertical line
                            if (date.getMinutes() == 45 && !section.children) {
                                return "hour-end";
                            }
                            return "";
                        };

                        //formats HEADER - lines and text
                        scheduler.templates.timeline_scalex_class = function (date) {
                            //"hour-end";   //bolds text
                            //"hour-start";  //bolds line

                            if (date.getMinutes() == 0) {
                                return "hour-end hour-start";
                            }
                            return "";
                            /*
                            if (!date.getMinutes()) {
                                return "hour-end";
                            } else if (date.getMinutes() == 45) {
                                return "hour-start";  //bolds line
                            }
                            return "";
                            */
                        };

                        //===============
                        //Data loading
                        //===============
                        //scheduler.config.lightbox.sections = [
                        //    {name: "description", height: 50, map_to: "text", type: "textarea", focus: true},
                        //    {name: "custom", height: 30, type: "select", options: sections, map_to: "section_id"},
                        //    {name: "time", height: 72, type: "time", map_to: "auto"}
                        //];

                        scheduler.templates.timeline_scale_label = function (key, label, section) {
                            var name = label;
                            if (section.capacity) {
                                name += " (" + section.capacity + ")";
                            }
                            return "<p style='color:" + section.color + "'>" + name + "</p>";
                        };


                        scheduler.serverList("eventType", [
                            {key: "R", label: "Reservation", short: "R"},
                            {key: "S", label: "Walk-in seated", short: "S"},
                            {key: "W", label: "Waitlisted seated", short: "W"},
                            {key: "B", label: "Block", short: "B"}
                        ]);


                        /*scheduler.config.lightbox.sections = [
                            {name: "description", height: 50, map_to: "text", type: "textarea", focus: true},
                            {name: "custom", height: 30, type: "timeline", options: null, map_to: "section_id"}, //type should be the same as name of the tab
                            {name: "time", height: 72, type: "time", map_to: "auto"}
                        ];*/

                        scheduler.locale.labels.section_custom = "Table";
                        scheduler.locale.labels.section_customer = "Customer";
                        scheduler.locale.labels.section_numberOfCovers = "Number Of Covers";
                        scheduler.locale.labels.section_type = "Type";
                        scheduler.locale.labels.section_notes = "Notes";


                        scheduler.config.lightbox.sections = [
                            {name: "customer", height: 30, map_to: "customer_name", type: "textarea", focus: true},
                            {name: "numberOfCovers", height: 30, map_to: "n_of_covers", type: "textarea"},
                            {name: "type", map_to: "type", type: "select", options: scheduler.serverList("eventType")},
                            {name: "notes", height: 50, map_to: "text", type: "textarea"},
                            {name: "custom", height: 30, type: "timeline", options: null, map_to: "section_id"}, //type should be the same as name of the tab
                            {name: "time", height: 72, type: "time", map_to: "auto"}
                        ];

                        //scheduler.config.container_autoresize = false;

                        if (DBG) console.log("INIT_START");
                        var initDate = new Date(new Date(moment(range.from).year(), moment(range.from).month(), moment(range.from).date(), moment(range.from).hour(), moment(range.from).minute()));
                        if (!isTimelineExists) scheduler.init('scheduler_here', initDate, "timeline");

                        if (DBG) console.log("INIT_END - START PARSE");
                        scheduler.parse(dhtmlx_events);
                        if (DBG) console.log("PARSE END");
                        var timeline = scheduler.getView();
                        vm.timelineView = timeline;
                        //var gridWidth = scheduler.getDataGridWidth;  //undefined
                        //console.log("gridWidth:" + gridWidth)
                        //timeline.scrollTo(gridWidth);
                        //timeline.scrollTo(10000);
                        if (DBG) console.log("(timeline._x_scroll:" + timeline._x_scroll);

                        var scrollToNow = function () {
                            if (DBG) console.log("timeline.getScrollPosition(): " + timeline.getScrollPosition());
                            if (DBG) console.log(timeline.getScrollPosition());
                            if (DBG) console.log(timeline);
                            if (DBG) console.log("(timeline._x_scroll:" + timeline._x_scroll);
                            var xsccroll_div2 = Math.round(timeline._x_scroll / 2);
                            if (DBG) console.log("xsccroll_div2:" + xsccroll_div2);
                            var mmt = moment();
                            var mmtMidnight = mmt.clone().startOf('day');
                            var diffMinutes = mmt.diff(mmtMidnight, 'minutes');
                            timeline.scrollTo(timeline._x_scroll * diffMinutes / (24 * 60));
                        }

                        if (DBG) console.log(moment().toDate());
                        if (moment().isSame(vm.currentDate, 'day')) {
                            var scrollToMoment = moment().subtract(60, 'minutes');
                            timeline.scrollTo(new Date(scrollToMoment.year(), scrollToMoment.month(), scrollToMoment.date(), scrollToMoment.hour(), scrollToMoment.minute()));
                        } else {
                            timeline.scrollTo(new Date(vm.currentDate.year(), vm.currentDate.month(), vm.currentDate.date(), 7, 0));
                        }
                        if (DBG) console.log(new Date(moment().year(), moment().month(), moment().date(), moment().hour(), moment().minute()));

                        //timeline.scrollTo(timeline.posFromDate(moment().format("YYYY-MM-DD HH:mm")));

                        if (vm.scrollPosition) {
                            vm.timelineView.scrollTo(vm.scrollPosition);
                            vm.scrollPosition = null;
                        }

                        /* var timeline_navline_div = document.getElementById("timeline_navline");
                           var timeline_navline_date = document.getElementById("timeline_navline_date");
                           timeline_navline_date.style.visibility = "hidden";
                           timeline_navline_date.style = "min-height: 0px;height: 0px;";
                           timeline_navline_div.style.visibility = "hidden";
                           timeline_navline_div.style = "min-height: 0px;height: 0px;";  */

                    });
            }
        );


    }
    ;


    function getRange(period) {
        if (period === 'custom') { // range selected on the date picker
            return {
                from: vm.filterFrom,
                to: vm.filterTo
            };
        } else {
            // pre-defined period
            return vm.ranges[period];
        }
    }

    vm.reload = function (isChanged) {

        if (true || isChanged == 'refresh') { //TODO check
            if (vm.timelineView)
                vm.scrollPosition = vm.timelineView.getScrollPosition(); // { left: 0, top: 0 }
        }


        vm.ranges = ranges_day;
        //if (isChanged) vm.selectedRange = 'today'; //resseting view to today

        vm.range = getRange(vm.selectedRange);

        vm.getTimelineData(vm.selectedRange);

        var params = {
            range: vm.selectedRange,
            from: null,
            to: null
        };
        if (vm.selectedRange === 'custom') {
            params.from = vm.filterFrom;
            params.to = vm.filterTo;
        }
        $state.go('tables.timeline', params, {notify: false}); // change the URL
        updateDatePickerText();
    };

// reservations list, page changed
    vm.pageChanged = function () {
        vm.getOpenReservations(vm.selectedRange);
    };

// paging data
    vm.currentPage = 1;
    vm.itemsPerPage = 100;

    if (isPredefinedRange($stateParams.range)) {
        vm.selectedRange = $stateParams.range;
        if (vm.selectedRange.startsWith('today') || vm.selectedRange.startsWith('tomorrow')) vm.viewType = 'daily';
        else vm.viewType = 'weekly';
    } else if ($stateParams.range === 'custom' && $stateParams.from && moment($stateParams.from).isValid() && $stateParams.to && moment($stateParams.to).isValid()) {
        vm.selectedRange = 'custom';
        vm.filterFrom = moment($stateParams.from).toISOString();
        vm.filterTo = moment($stateParams.to).toISOString();
    } else {
        vm.selectedRange = 'today';
        //vm.selectedRange = 'this_week';
        vm.viewType = 'daily';
    }

//vm.reload();

// initialize date picker
    $document.ready(
        function () {
            vm.pickerDates = $document.find('input[name="pickerDates"]');
            vm.pickerDates.daterangepicker(
                {
                    showDropdowns: true,
                    autoApply: true,
                    autoUpdateInput: false,
                    alwaysShowCalendars: true,
                    locale: {format: 'MMM D, YYYY'},
                    singleDatePicker: true,
                },
                function (start, end, label) {
                    vm.selectedRange = 'custom';
                    vm.filterFrom = moment(start).toISOString();
                    vm.filterTo = moment(end).toISOString();
                    vm.reload();
                }
            );
            updateDatePickerText();
        }
    );

    vm.openPicker = function () {
        vm.pickerDates.focus();
    };

    function isPredefinedRange(range) {
        //console.log("range" + range);
        if (range) {
            //console.log("vm.ranges_day" + vm.ranges_day);
            if (ranges_day[range] !== undefined) {
                vm.ranges = ranges_day;
                return true;
            }
        }
        vm.ranges = ranges_day;
        return false;
    }

    function updateDatePickerText() {
        if (vm.pickerDates) {
            if (vm.selectedRange == 'custom') {
                //vm.pickerDates.val(moment(vm.filterFrom).format('MMM D, YYYY') + ' - ' + moment(vm.filterTo).format('MMM D, YYYY'));
                vm.pickerDates.val(moment(vm.filterFrom).format('MMM D, YYYY'));
            } else {
                vm.pickerDates.val('');
            }
        }
    }
}

angular.module('seatonApp').controller('ReservationTimelineController', ReservationTimelineController);
