(function () {
    'use strict';

    angular
        .module('seatonApp')
        .controller('ImportCustomerController', ImportCustomerController);

    ImportCustomerController.$inject = ['$rootScope', '$state', 'ImportService', 'Notification', 'ParseLinks'];

    function ImportCustomerController($rootScope, $state, ImportService, Notification, ParseLinks) {
        var vm = this;


        vm.importAllCustomers = function() {

            var params = {
                'dataToImport': vm.dataToImport
            };

            ImportService.importAllCustomers(params, onSuccess, onError);

            function onSuccess(data, headers) {
                vm.importResult = data;
                Notification.info("Import finished, please check result below on the page.");
            }

            function onError(error) {
                Notification.error("Import request failed. Error = " + error.data.message);
            }


        }

        vm.importOneCustomer = function(event) {

        }

    }
})();
