(function () {
    'use strict';

    angular
        .module('seatonApp')
        .factory('OnlineReservationContext', OnlineReservationContext);

    OnlineReservationContext.$inject = ['$rootScope', '$localStorage', '$locale'];

    function OnlineReservationContext($rootScope, $localStorage, $locale) {

        var service = {
            storeLocationChannelPage: storeLocationChannelPage,
            storeSettings: storeSettings,
            storeRequest: storeRequest,
            storeReference: storeReference,
            storeCustomer: storeCustomer,
            storePaymentSettings: storePaymentSettings,
            storeVerificationSent: storeVerificationSent,
            storeProductModifiers: storeProductModifiers,
            storeFees: storeFees,

            getLocationId: getLocationId,
            getChannel: getChannel,
            getReservationPage: getReservationPage,
            getSettings: getSettings,
            getRequest: getRequest,
            getReference: getReference,
            getCustomer: getCustomer,
            getPaymentSettings: getPaymentSettings,
            getVerificationSent: getVerificationSent,
            getProductModifiers: getProductModifiers,
            getFees: getFees,

            clear: clear,
            clearModifiers: clearModifiers

        };

        return service;

        function storeLocationChannelPage(locationId, channel, reservationPage) {
            $localStorage.OnlineLocationID = locationId;
            $localStorage.OnlineChannel = channel;
            $localStorage.OnlineReservationPage = reservationPage;
        }

        function storeSettings(settings) {
            $localStorage.OnlineSettings = settings;
        }

        function storeRequest(request) {
            $localStorage.OnlineRequest = request;
        }

        function storeReference(reference) {
            $localStorage.OnlineReference = reference;
        }

        function storeCustomer(customer) {
            $localStorage.OnlineCustomer = customer;
        }

        function storePaymentSettings(payment) {
            $localStorage.OnlinePaymentSettings = payment;
        }

        function storeVerificationSent(sent) {
            $localStorage.OnlineVerificationSent = sent;
        }

        function storeProductModifiers(modifiers) {
            $localStorage.OnlineProductModifiers = modifiers;
        }

        function storeFees(fees) {
            $localStorage.OnlineFees = fees;
        }

        function clearModifiers() {
            // MODIFIER PAGE
            delete $localStorage.OnlineProductModifiers;
            delete $localStorage.OnlineFees;

        }

        function clear() {

            // PROXY PAGE
            delete $localStorage.OnlineLocationID;
            delete $localStorage.OnlineChannel;
            delete $localStorage.OnlineReservationPage;

            // REQUEST PAGE
            delete $localStorage.OnlineSettings;
            delete $localStorage.OnlineRequest;

            clearModifiers();

            // CONTACT PAGE
            delete $localStorage.OnlineCustomer;
            delete $localStorage.OnlinePaymentSettings;
            delete $localStorage.OnlineVerificationSent;


            // GENERAL
            delete $localStorage.OnlineReference;
        }

        function getLocationId() {
            return $localStorage.OnlineLocationID;
        }

        function getChannel() {
            return $localStorage.OnlineChannel;
        }

        function getReservationPage() {
            return $localStorage.OnlineReservationPage;
        }

        function getSettings() {
            return $localStorage.OnlineSettings;
        }

        function getRequest() {
            return $localStorage.OnlineRequest;
        }

        function getReference() {
            return $localStorage.OnlineReference;
        }

        function getCustomer() {
            return $localStorage.OnlineCustomer;
        }

        function getPaymentSettings() {
            return $localStorage.OnlinePaymentSettings;
        }

        function getVerificationSent() {
            return $localStorage.OnlineVerificationSent;
        }

        function getProductModifiers() {
            return $localStorage.OnlineProductModifiers
        }

        function getFees() {
            return $localStorage.OnlineFees;
        }

    }
})();
