(function() {
    'use strict';
    angular
        .module('seatonApp')
        .factory('ImportService', ImportService);

    ImportService.$inject = ['$resource'];

    function ImportService($resource) {
        var resourceUrl = 'api/auth/admin/import';

        return $resource(resourceUrl, {}, {
            'importAllEvents': { method:'POST', url: 'api/admin/importAllEvents'},
            'importOneEvent': { method:'POST', url: 'api/admin/importOneEvent'},
            'importAllCustomers': { method:'POST', url: 'api/admin/importAllCustomers'},
            'importOneCustomer': { method:'POST', url: 'api/admin/importOneCustomer'}
        });
    }
})();
