(function () {
    'use strict';

    angular
        .module('seatonApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('tables.block', {
                parent: 'tables',
                url: '/block',
                data: {
                    authorities: ['ROLE_USER']
                },
                views: {
                    'content@app': {
                        templateUrl: 'app/tables/block/block.html',
                        controller: 'ReservationBlockController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        //$translatePartialLoader.addPart('allergy');
                        $translatePartialLoader.addPart('global');
                        //$translatePartialLoader.addPart('reporting.aggregated');
                        return $translate.refresh();
                    }]
                }
            });
    }

})();
