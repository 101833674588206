(function () {
        'use strict';

        angular
            .module('seatonApp')
            .controller('TopNavbarController', TopNavbarController);

        TopNavbarController.$inject = ['$state', '$scope', '$window', 'Auth', 'Principal', 'ApplicationContext', 'SweetAlert', 'HelpPagesMapping'];

        function TopNavbarController($state, $scope, $window, Auth, Principal, ApplicationContext, SweetAlert, HelpPagesMapping) {
            var vm = this;
            vm.isAuthenticated = Principal.isAuthenticated;
            vm.HelpPagesMapping = HelpPagesMapping;
            vm.showHelp = HelpPagesMapping.showHelp;
            vm.showHelpButton = false;
            vm.keyword = null;

            vm.$state = $state;

            vm.hashedLocationId = ApplicationContext.getLocation().hashedLocationId;
            //console.log('vm.$state: ' + vm.$state.current.name);

            $(window).scroll(function (event) {
                var pageYOffset = $window.pageYOffset;
                var oldState = vm.showHelpButton;
                //console.log(pageYOffset);
                if (pageYOffset > 7 || pageYOffset == undefined) {
                    vm.showHelpButton = true;
                } else {
                    vm.showHelpButton = false;
                }
                if (oldState !== vm.showHelpButton) {
                    //console.log('$scope.$apply()');
                    $scope.$apply();
                }
            });

            vm.logout = logout;
            vm.toggleNavbar = toggleNavbar;
            vm.collapseNavbar = collapseNavbar;

            vm.searchEvent = function() {
                $state.go("tables.search", {keyword: vm.keyword});
            }

            function logout() {
                var authType = ApplicationContext.getAuthType();

                collapseNavbar();
                Auth.logout();

                if (authType == "ADMIN") {
                    console.log("Logout. Open admin login screen.");
                    $state.go('account.login');
                } else {
                    console.log("Logout. Clover logout screen.");
                    $state.go('account.clover.logout');
                }
            }

            function toggleNavbar() {
                vm.isNavbarCollapsed = !vm.isNavbarCollapsed;
            }

            function collapseNavbar() {
                vm.isNavbarCollapsed = true;
            }

        }
    }
)
();
