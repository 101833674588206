(function () {
    'use strict';

    angular
        .module('seatonApp')
        .controller('CustomerListController', CustomerListController);

    CustomerListController.$inject = ['$scope', '$state', '$stateParams', 'CustomerResource', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams'];

    function CustomerListController($scope, $state, $stateParams, CustomerResource, ParseLinks, AlertService, paginationConstants, pagingParams) {
        var vm = this;
        vm.errorMsg = null;
        vm.search = search;
        vm.sort = sort;
        vm.exportCSV = exportCSV;

        function search() {
            loadAll();
        }

        console.log('$stateParams and $state');
        console.log($stateParams);
        console.log($state);

        vm.listNames = {
            'newSinceLastLogin': 'Customers registered since last login',
            'createdDate': 'Customers by registration date',
            'totalSpend': 'Top regulars by spending',
            'avgSpendPerVisit': 'Top regulars by spend per visit',
            'totalVisits': 'Top regulars by number of visits',
            'totalReservation': 'Top regulars by number of reservations',
            'totalNoShow': 'Top regulars by no-show number',
            'totalCanceled': 'Top regulars by number of cancellations',

            'basicSearch': 'Customers matching defined criteria'
        };

        vm.searchKeyword = $stateParams.keyword;

        vm.listType = $stateParams.listType;
        if (vm.listType != 'basicSearch') {
            $stateParams.keyword = null;
            vm.predicate = $stateParams.listType;
            if (vm.searchKeyword != null) $state.go('customers.list', $stateParams, {notify: false}); //just change url - remove koyword as it's not needed
        } else
            vm.predicate = 'createdDate';

        vm.listName = vm.listNames[vm.listType];

        vm.loadPage = loadPage;
        vm.reload = reload;

        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;

        function reload() {
            console.log('changed listType:' + vm.listType);
            if (vm.listType != 'basicSearch') vm.predicate = vm.listType; else vm.predicate = 'createdDate';
            vm.transition();
        }

        loadAll();

        function loadAll() {
            vm.errorMsg = null;
            vm.customers = {};

            if (vm.listType == 'basicSearch') {
                console.log(vm.searchKeyword);

                if (typeof vm.searchKeyword === 'undefined') {
                    vm.errorMsg = "Search keyword not valid (too short)";
                    return;
                }
                if (vm.searchKeyword == null || vm.searchKeyword == '') {
                    return;
                }

                vm.searchKeyword = vm.searchKeyword.replace(/([A-Za-z])\1{2,}/g, '$1');

                if (vm.searchKeyword.length < 3) {
                    vm.errorMsg = "Search keyword not valid (too short)";
                    return;
                }

                $stateParams.keyword = vm.searchKeyword;
                $state.go('customers.list', $stateParams, {notify: false}); // change the URL, add keyword
            }

            CustomerResource.lists({
                listType: vm.listType,
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort(),
                searchKeyword: vm.searchKeyword
            }, onSuccess, onError);


            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.customers = data;
                vm.page = pagingParams.page;
                if (vm.totalItems == 0) vm.errorMsg = 'No customers found matching criteria!';
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function sort() {
            var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
            if (vm.predicate !== 'id') {
                result.push('id');
            }
            return result;
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            console.log('transition: vm.predicate:' + vm.predicate + ' vm.listType:' + vm.listType);
            vm.listType = vm.predicate;

            $state.go($state.$current, {
                listType: vm.listType,
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }

        function exportCSV() {
            var allResults = [];
            var pageIndex = 0;
            searchRecursive(allResults, pageIndex);
        }

        function searchRecursive(allResults, pageIndex) {
            CustomerResource.lists(
                {
                    listType: vm.listType,
                    page: pageIndex,
                    size: 1500,
                    sort: sort(),
                    searchKeyword: vm.searchKeyword
                },
                function (data) {
                    var json1 = data;
                    // Check if there is any data in the current page
                    if (json1.length > 0) {
                        // Concatenate the results
                        allResults = allResults.concat(json1);
                        // Increment the pageIndex and continue searching
                        pageIndex++;
                        searchRecursive(allResults, pageIndex);
                    } else exportResultsToCSV(allResults);
                }
            );
        }

        function exportResultsToCSV(json1) {
            var json = [];
            for (var i = 0; i < json1.length; i++) {
                json.push(flattenJSON(json1[i])); //push flattened row
            }
            var fields = Object.keys(json[0]);
            var replacer = function (key, value) {
                return value === null ? '' : value
            }
            //flatten
            var csv = json.map(function (row) {
                return fields.map(function (fieldName) {
                    return JSON.stringify(row[fieldName], replacer)
                }).join(',')
            })
            csv.unshift(fields.join(',')) // add header column
            csv = csv.join('\r\n');

            var blob = new Blob([csv], {type: 'text/csv;charset=utf-8;'});
            var url = URL.createObjectURL(blob);
            var a = document.createElement('a');
            a.href = url;
            a.download = 'customer_list.csv';
            a.click();
            URL.revokeObjectURL(url);
        }

        function flattenJSON(obj, prefix) {
            var flattened = {};
            if (!prefix) {
                prefix = "";
            }
            for (var key in obj) {
                if (obj.hasOwnProperty(key)) {
                    if (typeof obj[key] === "object" && obj[key] !== null) {
                        var nested = flattenJSON(obj[key], prefix + key + ".");
                        flattened = Object.assign(flattened, nested);
                    } else {
                        flattened[prefix + key] = obj[key];
                    }
                }
            }
            return flattened;
        }
    }
})();
