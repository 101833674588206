(function () {
    'use strict';
    angular.module('seatonApp').controller('OnlineReserveController', OnlineReserveController);

    OnlineReserveController.$inject = ['$rootScope', '$scope', '$state', '$document', '$stateParams', '$sce', 'Log', 'PublicResource',
        'OnlineReservationContext'];

    // required to whitelist the Google Mpas URL to use in the iFrame
    angular.module('seatonApp').filter('trustAsResourceUrl', ['$sce', function ($sce) {
        return function (val) {
            return $sce.trustAsResourceUrl(val);
        };
    }]);

    function OnlineReserveController($rootScope, $scope, $state, $document, $stateParams, $sce, Log, PublicResource,
                                     OnlineReservationContext) {

        // INITIALIZE
        var vm = this;

        vm.DATE_FORMAT = 'YYYY-MM-DD';
        vm.DATE_PICKER_FORMAT = 'MMM D, YYYY';
        vm.TIME_SERVER_FORMAT = 'HH:mm:ss.SSS';
        vm.TIME_DISPLAY_FORMAT = 'hh:mmA';
        vm.MINMAX_PICKER_DATE_FORMAT = 'MM/DD/YYYY';
        vm.moment = moment;

        vm.productNotSelectedError = false;
        vm.todayPicker = vm.moment().format(vm.MINMAX_PICKER_DATE_FORMAT);
        vm.maxReservationDatePicker = "12/31/9999";
        vm.settings = null;
        vm.minPartySize = 1;
        vm.maxPartySize = 6;
        vm.seatOptions = [];
        vm.additionalSeatOptions = null;

        vm.selectedDay = vm.moment().format(vm.DATE_FORMAT);
        vm.selectedProductId = null;
        vm.availability = null;
        vm.datePicker = null;
        vm.productAlertDate = null;

        // function definition
        vm.init = init;
        vm.getReserveSettings = getReserveSettings;
        vm.initPicker = initPicker;
        vm.getAvailability = getAvailability;
        vm.filterByParams = filterByParams;
        vm.filterAvailability = filterAvailability;
        vm.calculateButtonColor = calculateButtonColor;
        vm.alternativeProduct = alternativeProduct;
        vm.onParametersChange = onParametersChange;
        vm.findProductById = findProductById;
        vm.proceedToContactPage = proceedToContactPage;
        vm.pickAPartySizeText = pickAPartySizeText;

        // initialize online reserve page
        vm.init();

        function init() {

            // check if location id parameter is present
            vm.hashedLocationId = OnlineReservationContext.getLocationId();
            Log.debug("OnlineReserveController", "Hashed locationId id is '" + vm.hashedLocationId + "'");
            if (!vm.hashedLocationId) {
                // if location id not defined abort and show fatal error message
                onError();
            } else {
                vm.request = OnlineReservationContext.getRequest(); // reservation request -> date, time & party_size, productId
                vm.channel = OnlineReservationContext.getChannel(); // channel

                //Log.debug("OnlineReserveController", "Params: locationId:" + vm.hashedLocationId +
                //    ", resRequest {date:" + vm.request.date + ", time:" + vm.request.time + ", party_size:" + vm.request.party_size +
                //", productId:" + vm.request.productId +" }, reference:"+vm.reference+", channel:" + vm.channel + ".");

                vm.numberOfPersons = vm.request.party_size;

                // if location id defined proceed with initialization
                getReserveSettings();
            }
        }


        function getReserveSettings() {

            PublicResource.getReserveSettings({
                hashedLocationId: vm.hashedLocationId,
                productId: vm.request.productId
            }, onSuccess, onError);

            function onSuccess(data, headers) {
                vm.settings = data;

                vm.formattedWebPageLink = addHttpPrefix(vm.settings.businessWebPage); // adds http: prefix if missing

                vm.settings.coordinate = encodeURIComponent(vm.settings.coordinate);
                OnlineReservationContext.storeSettings(vm.settings);

                if (vm.settings.maxReservationDate) {
                    vm.maxReservationDatePicker = moment(vm.settings.maxReservationDate, vm.DATE_FORMAT).format(vm.MINMAX_PICKER_DATE_FORMAT);
                }

                // generate party size list
                vm.minPartySize = vm.settings.minSeats;
                if ((vm.settings.maxSeats - vm.settings.minSeats + 1) > 8) {
                    vm.maxPartySize = vm.minPartySize + 7; /// 7 button fit on list
                    vm.additionalSeatOptions = [];
                    for (i = vm.maxPartySize + 1; i <= vm.settings.maxSeats; i++) {
                        vm.additionalSeatOptions.push({
                            id: i,
                            label: isBamboo() ? i + " BAMBOO BIRYANI" : i + " Persons"
                        });
                    }
                } else {
                    vm.maxPartySize = vm.settings.maxSeats;
                }

                for (var i = vm.minPartySize; i <= vm.maxPartySize; i++) {
                    vm.seatOptions.push(i);
                }

                // DEFAULT VALUES
                // date
                if (vm.request.date) {
                    vm.selectedDay = vm.request.date;
                } else {
                    vm.selectedDay = moment().format(vm.DATE_FORMAT); // today, always by default today will be choose as reservation date
                }
                vm.selectedDayFormatted = moment(vm.selectedDay, vm.DATE_FORMAT).format(vm.MINMAX_PICKER_DATE_FORMAT); // format to match date picker

                // time
                if (vm.request.time) {
                    vm.selectedTime = vm.request.time;
                } else {
                    vm.selectedTime = null;
                }

                // party size
                if (!vm.request.party_size) { // if party size not received outside
                    if (vm.settings.minSeats > vm.numberOfPersons) vm.numberOfPersons = vm.settings.minSeats;
                    else vm.numberOfPersons = 2;
                } else {
                    vm.numberOfPersons = parseInt(vm.request.party_size);
                }

                // default selected product id
                if (vm.settings.productChosenByGuest && vm.settings.productList) { // productList sent from server .. product activated
                    if (vm.request.productId) {
                        // productId received through input parameters
                        vm.selectedProductId = findProductById(vm.request.productId).id;
                    } else if (vm.settings.productType == 'PRODUCT' && vm.settings.productList.length > 0) {
                        vm.selectedProductId = vm.settings.productList[0].id;
                    } else if (vm.settings.productType == 'DURATION') {
                        // select third from the list if available, if not second, if not first, if not leave empty
                        if (vm.settings.productList.length > 2) {
                            vm.selectedProductId = vm.settings.productList[2].id;
                        } else if (vm.settings.productList.length > 1) {
                            vm.selectedProductId = vm.settings.productList[1].id;
                        } else if (vm.settings.productList.length > 0) {
                            vm.selectedProductId = vm.settings.productList[0].id;
                        }
                    }
                } else { // product not activated
                    vm.selectedProductId = null; // if product null server will decide about reservation product
                }
                vm.productNotSelectedError = false;

                //Log.debug("OnlineReserveController", " After initialization -> selectedDay:" + vm.selectedDay + ", selectedTime:" + vm.selectedTime
                //    + ", numberOfPersons:" + vm.numberOfPersons + ", selectedProductId:" + vm.selectedProductId +".");

                getAvailability();

                $document.ready(
                    function () {
                        //console.log('prije vm.initPicker(): ' + vm.selectedDay);
                        vm.selectedDayFormatted = moment(vm.selectedDay, vm.DATE_FORMAT).format(vm.MINMAX_PICKER_DATE_FORMAT);
                        vm.initPicker();
                    }
                );


            }
        }

        function onParametersChange() {
            getAvailability();
            vm.selectedDayFormatted = moment(vm.selectedDay, vm.DATE_FORMAT).format(vm.MINMAX_PICKER_DATE_FORMAT);
            //console.log('onParametersChange() vm.selectedDayFormatted: ' + vm.selectedDayFormatted);
            //vm.datePicker.val(vm.selectedDayFormatted);
            vm.initPicker();
        }

        function getAvailability() {

            vm.product = null; // if duration null server choose duration
            vm.productNotSelectedError = false;
            vm.productAlertDate = null;

            if (vm.settings.productChosenByGuest) {
                // product chosen by guest
                vm.product = findProductById(vm.selectedProductId);
                //Log.debug("OnlineReserveController", "Product found:" + JSON.stringify(vm.product));
                if (!vm.product) { // product not found report error
                    vm.productNotSelectedError = true;
                    return;
                }
                OnlineReservationContext.clearModifiers();
            }
            //console.log(vm.selectedDay + '  ' + vm.product.startDate + '  ' + vm.product.endDate);

            //check if selected date is between product start and end date - if it is not, change selected date to product start date and show alert
            if (vm.product != null && vm.product.startDate != null && vm.product.endDate != null) {
                if (moment(vm.selectedDay).isSameOrAfter(vm.product.startDate) && moment(vm.selectedDay).isSameOrBefore(vm.product.endDate)) {
                    //console.log('BETWEEN');
                    vm.productAlertDate = null;
                } else {
                    //console.log('NOT BETWEEN');
                    vm.productAlertDate = 'Date changed to ' + moment(vm.product.startDate).format('dddd, Do MMMM YYYY') + ' !';
                    if (!moment(vm.product.startDate).isSame(moment(vm.product.endDate)))
                        vm.productAlertDate += ' Available date range is from: ' + moment(vm.product.startDate).format('MMMM Do YYYY') + ' to ' + moment(vm.product.endDate).format('MMMM Do YYYY') + '.';
                    else vm.productAlertDate += ' Selected product available only at that date.';
                    vm.selectedDay = moment(vm.product.startDate).format(vm.DATE_FORMAT);

                }
            }

            /*if (vm.product != null) {
                Log.debug("OnlineReserveController", "Selected date:" + vm.selectedDay + ", selected number of persons:" + vm.numberOfPersons +
                    ", selected product {id:" + vm.product.id + ", name:"+vm.product.name+"}.");
            }else {
                Log.debug("OnlineReserveController","Selected date:" + vm.selectedDay + ", selected number of persons:" + vm.numberOfPersons + ", product: null.");
            }*/

            PublicResource.getReserveAvailability(
                {
                    hashedLocationId: vm.hashedLocationId
                }, {
                    requestedDate: vm.selectedDay,
                    requestedPerson: vm.numberOfPersons,
                    product: vm.product
                }, onSuccess, onError);


            function onSuccess(data, headers) {
                if (vm.product != null)
                    vm.seatingOptionIds = vm.product.seatingOptionIds;
                vm.availability = data;
            }

        }

        function checkIsDateInsideProductInterval(date, product) {
            if (date == null) return false;
            if (product != null && product.startDate != null && product.endDate != null) {
                if (moment(date).isSameOrAfter(product.startDate) && moment(date).isSameOrBefore(product.endDate)) {
                    //console.log('BETWEEN');
                    return true;
                }
            }
            return false;
        }

        function proceedToContactPage(containerId, selectedTime, containerName) {

            //Log.debug("OnlineCreateLegacyController",
            //    "Selected container id is " + containerId + ". selected timeslot is " + selectedTime);

            vm.selectedTime = selectedTime;
            vm.actionSubmitted = true;
            vm.inputErrorOccured = false;

            if (!vm.selectedTime || !vm.selectedDay) { // if time not selected report error
                vm.inputErrorOccured = true;
                vm.actionSubmitted = false;
                return;
            }

            vm.product = null; // if duration null server choose duration
            if (vm.settings.productChosenByGuest) {
                // product chosen by guest
                vm.product = findProductById(vm.selectedProductId);
                //Log.debug("OnlineCreateLegacyController", "Product found:"+JSON.stringify(vm.product));
                if (!vm.product) { // product not found report error
                    vm.actionSubmitted = false;
                    vm.inputErrorOccured = true;
                    return;
                }
            }

            var request = {
                date: vm.selectedDay,
                time: vm.selectedTime,
                containerId: containerId,
                containerName: containerName,
                party_size: vm.numberOfPersons,
                product: vm.product
            }
            OnlineReservationContext.storeRequest(request);
            OnlineReservationContext.storeReference("RESERVE");

            // if modifiers enabled on product open modifer screen, if not open contact details screen
            if (vm.product && vm.product.modifierEnabled) {
                $state.go("public.online.modifier");
            } else {
                $state.go("public.online.contact");
            }
        }


        function onError(error) {
            Log.debug("OnlineResereCreateController", "Unexpected error:" + error + ".");
            vm.actionSubmitted = false;
            OnlineReservationContext.storeReference("RESERVE");

            $state.go("public.online.error");
        };

        // initialize date & time picker
        function initPicker() {
            // init daterangepicker
            vm.datePicker = $('#daterangepicker1').daterangepicker({
                singleDatePicker: true,
                parentEl: "#daterangepicker1-container",
                autoApply: true,
                minDate: vm.todayPicker,
                maxDate: vm.maxReservationDatePicker,
                startDate: vm.selectedDayFormatted,
                locale: {
                    format: vm.MINMAX_PICKER_DATE_FORMAT
                }
            });

            // range update listener
            vm.datePicker.on('apply.daterangepicker', function (ev, picker) {
                vm.selectedDay = picker.startDate.format(vm.DATE_FORMAT);
                onParametersChange();
            });

            // prevent hide after range selection
            vm.datePicker.data('daterangepicker').hide = function () {
            };
            // show picker on load
            vm.datePicker.data('daterangepicker').show();

            // define value
            //TODO naci nacin kako pridodjeliti datum an daterangepicker
            //vm.datePicker.data('daterangepicker').setStartDate(vm.selectedDayFormatted);

        };

        function filterByParams(timeSlot) {

            // do not display if event exceed closing business hours
            if (vm.settings.productType != 'MEAL') {
                var selectedProduct = vm.product;
                if (!selectedProduct) selectedProduct = findProductById(vm.selectedProductId);
                //Log.debug("OnlineReserveCreateController", "Selected product:" + JSON.stringify(selectedProduct) + ".");
                if (selectedProduct && timeSlot.endWorkingTime) {

                    var endWorkingTime = moment(timeSlot.endWorkingTime, vm.TIME_SERVER_FORMAT);
                    var chosenTimeSlot = moment(timeSlot.time, vm.TIME_SERVER_FORMAT);
                    var endEventTime = moment(chosenTimeSlot).add(selectedProduct.duration - 1, 'minutes');
                    //Log.debug("OnlineReserveCreateController", "End time:" + endWorkingTime + ", chosen time slot:" + chosenTimeSlot + ", end event time:" + endEventTime + ".");

                    if (endEventTime.isAfter(endWorkingTime)) {
                        return false;
                    }
                }
            }

            if (timeSlot.availableCapacity >= vm.numberOfPersons && //  if available cover capacity greater than requested number of persons and
                (timeSlot.limit == -1 ||  // max allowed reservation per time slot not activated or
                    timeSlot.limit > timeSlot.actualAmount)) { //  number of event equal or higher then maximum allowed

                return true;
            }
            return false;
        }

        function filterAvailability(availability) {
            //console.log("filterAvailability:" + JSON.stringify(availability));
            if (vm.product == null) return true;

            //filtering of seating areas which have active DATES in OTHER products and have DEFINED AREAS
            if (vm.settings.productList) {
                for (var i = 0; i < vm.settings.productList.length; i++) {
                    if (vm.settings.productList[i].id != vm.product.id) {   //check only other products than selected
                        if (checkIsDateInsideProductInterval(vm.selectedDay, vm.settings.productList[i])) {
                            //check if selected seating area is in other product
                            if (vm.settings.productList[i].seatingOptionIds != null) {
                                var IdsArray = vm.settings.productList[i].seatingOptionIds.split(",");
                                if (IdsArray.includes('' + availability.id)) {
                                    //console.log("blocking by other product:" + vm.settings.productList[i].name + 'availability: ' + availability.name);
                                    return false;
                                }
                            }
                        }
                    }
                }
            }

            //filtering of seating areas which are NOT DEFINED in current product (if list of areas is empty show all)
            if (vm.product.seatingOptionIds == null) return true;
            if (vm.product.seatingOptionIds == '') return true; // if no seating areas are defined -> show all
            var IdsArray = vm.product.seatingOptionIds.split(",");
            if (IdsArray.includes('' + availability.id))
                return true;
            else {
                //console.log("blocking by SELECTED product: availability: " + availability.name);
                return false;
            }
        }

        function calculateButtonColor(treshold, timeSlot) {
            if ((timeSlot.availableCapacity / timeSlot.capacity * 100) < treshold) {
                return "btn-warning"
            }
            return "btn-success";
        }

        function alternativeProduct(productType) {
            if (productType == 'DURATION') {
                return " or duration"
            } else if (productType == 'PRODUCT') {
                return " or product"
            }
        }

        function findProductById(productId) {
            if (vm.settings.productList) {
                for (var i = 0; i < vm.settings.productList.length; i++) {
                    if (vm.settings.productList[i].id == productId)
                        return vm.settings.productList[i];
                }
            }
            // product not found for product id
            return null;
        }

        vm.encodeURL = function () {
            return encode
        }

        function pickAPartySizeText() {
            if (isBamboo()) return "NUMBER OF BAMBOO BIRYANI";
            return "PICK A PARTY SIZE";
        }

        function isBamboo() {
            if (vm.hashedLocationId == "7EDE32B8") return true;
            //if(vm.hashedLocationId=="7653B6E8") return true; // local test
            return false;
        }

        // if the web site does not start with 'http(s)' this function will add the prefix http://
        function addHttpPrefix(originalLink) {
            if (originalLink) {
                if (originalLink.startsWith('http')) {
                    return originalLink;
                } else {
                    return "http://" + originalLink;
                }
            } else {
                return originalLink;
            }
        }

    }

})();



