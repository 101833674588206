(function () {
    'use strict';
    angular.module('seatonApp').controller('OnlineContactController', OnlineContactController);

    OnlineContactController.$inject = ['$rootScope', '$scope', '$state', '$document', '$stateParams', 'Log', 'PublicResource',
                                        'OnlineReservationContext', 'TextUtil'];

    function OnlineContactController($rootScope, $scope, $state, $document, $stateParams, Log, PublicResource,
                                     OnlineReservationContext, TextUtil) {

    // INITIALIZE
        var vm = this;
        vm.allowSMS = true;

        vm.DATE_FORMAT = 'YYYY-MM-DD';
        vm.TIME_SERVER_FORMAT = 'HH:mm:ss.SSS';
        vm.TIME_DISPLAY_FORMAT = 'hh:mmA';
        vm.moment = moment;

        vm.inputErrorOccured = false;
        vm.actionSubmitted = false;

        vm.resourceNotAvailableAnymore = false;
        vm.productChosenByGuest = false;
        vm.productList = [];

        vm.resourceText = TextUtil.translate("resource","s");

        // function definition
        vm.makeReservation = makeReservation;
        vm.inputNOK = inputNOK;
        vm.resetSecondPage = resetSecondPage;
        vm.secondPageActionButtonText = secondPageActionButtonText;
        vm.inputErrorOccurs = inputErrorOccurs;
        vm.reformatPhone = reformatPhone;
        vm.reschedule = reschedule;
        vm.setAndValidateParameters = setAndValidateParameters;

        // PARAMETERS AND SETTINGS
        vm.locationId = OnlineReservationContext.getLocationId();
        Log.debug("OnlineContactController", "Hashed locationId id is '" + vm.locationId + "'");
        if (!vm.locationId) {
            // if location id not return to reservation proxy
            $state.go("public.online.reservation");
        }
        vm.request = OnlineReservationContext.getRequest();
        vm.customer = OnlineReservationContext.getCustomer();
        vm.channel = OnlineReservationContext.getChannel();
        vm.reference = OnlineReservationContext.getReference();
        vm.reservationPage = OnlineReservationContext.getReservationPage();
        vm.settings = OnlineReservationContext.getSettings();
        vm.productModifiers = OnlineReservationContext.getProductModifiers();
        vm.fees = OnlineReservationContext.getFees();

        Log.debug("OnlineContactController",
            "Input params. Location id:" + vm.locationId
            + "; Request: " + JSON.stringify(vm.request)
            + "; Customer: " + JSON.stringify(vm.customer)
            + "; Channel: " + vm.channel
            + "; Reference: " + vm.reference
            + "; ReservationPage: " + vm.reservationPage
            + "; Settings: " + JSON.stringify(vm.settings)
        );

        vm.setAndValidateParameters();

        // set parameters
        function setAndValidateParameters() {

            if(!vm.channel || !vm.request || !vm.reference || !vm.settings || !vm.reservationPage) {
                Log.debug("OnlineContactController","One of parameter is not defined, go to first step in process.");

                // store new reference
                OnlineReservationContext.storeReference("CONTACT");

                $state.go("public.online.reservation");
                return;
            }

            vm.phoneRegion = vm.settings.phoneRegion;
            vm.phonePrefix = vm.settings.phonePrefix;
            vm.productChosenByGuest = vm.settings.productChosenByGuest;

            if(!vm.customer) vm.customer = {};

            var localeApp = 'en';
            if (vm.phoneRegion == 'US') {
                localeApp = 'en';
                $rootScope.currencyIcon = 'fa fa-dollar';
            } else if (vm.phoneRegion == 'GB') {
                localeApp = 'en-gb';
                $rootScope.currencyIcon = 'fa fa-gbp';
            } else if (vm.phoneRegion == 'IE') {
                localeApp = 'en-ie';
                $rootScope.currencyIcon = 'fa fa-euro';
            } else if (vm.phoneRegion == 'CA') {
                localeApp = 'en-ca';
                $rootScope.currencyIcon = 'fa fa-money';
            }

        };

        // make reservation or proceed to payment page if payment enabled
        function makeReservation(phoneValid) {

            if (vm.inputNOK(phoneValid)) {
                vm.inputErrorOccured = true;
                Log.debug("Input not ok");
                return;
            } else {
                vm.inputErrorOccured = false;
            }

            // disable create button, so that the user does not accidentally send two requests
            vm.actionSubmitted = true;

            Log.debug("OnlineContactController: Payment required " + paymentRequired() + ".","ONLINE Reservation");
            if(paymentRequired()) {

                var productId = null;
                if(vm.request.product) {
                    productId = vm.request.product.id;
                }
                PublicResource.getPaymentSettings(
                    {
                        hashedLocationId: vm.locationId
                    },
                    {
                        // reservation requested params
                        requestedDate: vm.request.date,
                        requestedTime: vm.request.time,
                        numberOfPersons: vm.request.party_size,
                        containerId: vm.request.containerId,
                        productId: productId,
                        productType: vm.settings.productType,
                        productModifierList: vm.productModifiers,
                        onlineFee: vm.fees
                    },
                    function (data) {
                        Log.debug("OnlineContactController","Public key fetch, status="+ data.status +": key="+data.key+":");
                        vm.actionSubmitted = false;
                        if(data.status<0) {
                            onError("Public key not fetched from Clover.");
                        } else {
                            vm.customer.occasion = 1;
                            OnlineReservationContext.storeCustomer(vm.customer);
                            OnlineReservationContext.storePaymentSettings(data);

                            // store new reference
                            OnlineReservationContext.storeReference("CONTACT");

                            if(!vm.fees) {
                                vm.fees = {
                                    serviceCharge: 0,
                                    reservation: data.totalAmount,
                                    totalWithoutServiceCharge: data.totalAmount,
                                    serviceChargeFee:0,
                                    total: data.totalAmount
                                };
                                OnlineReservationContext.storeFees(vm.fees);
                            }

                            // if payment enabled go to payment page
                            $state.go("public.online.payment");
                        }
                    },
                    onError
                );

            } else {
                // if payment not enabled complete reservation at this step
                PublicResource.prereserve(
                    {
                        hashedLocationId: vm.locationId
                    },
                    {
                        // reservation requested params
                        requestedDate: vm.request.date,
                        requestedTime: vm.request.time,
                        containerId: vm.request.containerId,
                        containerName: vm.request.containerName,
                        numberOfPersons: vm.request.party_size,
                        product: vm.request.product,
                        // other
                        productChosenByGuest: vm.settings.productChosenByGuest,
                        productType: vm.settings.productType,
                        // customer details
                        firstName: vm.customer.firstName,
                        lastName: vm.customer.lastName,
                        email: vm.customer.primaryEmail,
                        phone: vm.customer.primaryPhone,
                        note: vm.customer.note,
                        // other
                        channel: vm.channel,
                        occasion: 1
                    },
                    function (data) {

                        vm.resourceNotAvailableAnymore = data.resourceNotAvailable;
                        vm.actionSubmitted = false;

                        if (vm.resourceNotAvailableAnymore == false) {
                            OnlineReservationContext.storeCustomer(vm.customer);
                            OnlineReservationContext.storeVerificationSent(data.verificationSent);

                            // store new reference
                            OnlineReservationContext.storeReference("CONTACT");

                            $state.go("public.online.success");
                        }
                    },
                    onError
                );
            }
        };

        // validate second screen input
        function inputNOK (phoneValid) {
            var input =
                !(vm.request.party_size > 0) ||
                !vm.customer ||
                !vm.customer.firstName ||
                !vm.customer.lastName ||
                !vm.customer.primaryEmail ||
                !(vm.customer.primaryPhone && phoneValid);

            return input;
        };

        // reset second page form
        function resetSecondPage () {
            // workaround for https://github.com/angular/angular.js/issues/2743
            jQuery("input[name='primaryEmail']").val("");
            jQuery("input[name='primaryPhone']").val("");
            vm.customer = null;
            vm.inputErrorOccured = false;
        };

        // action button text depends on payment enabled
        function secondPageActionButtonText() {
            if(paymentRequired()) {
                return "Proceed to payment page";
            } else {
                return "Complete reservation";
            }
        };

        function paymentRequired() {
            if(vm.settings.enablePayment) {
                if(vm.settings.productType!='PRODUCT') return true;
                if(vm.request.product) {
                    var total = vm.request.product.fee;
                    if(vm.fees) {
                        total += vm.fees.total;
                    }
                    if(total>0) {
                        return true;
                    }
                } else {
                    return true;
                }
            }
            return false;
        }


    // HANDLE ERRORS

        function inputErrorOccurs () {
            return vm.inputErrorOccured;
        };

        function onError(error) {
            Log.debug("OnlineCreateLegacyController", "Unexpected error:" + error + ".");
            vm.actionSubmitted = false;

            // store new reference
            OnlineReservationContext.storeReference("CONTACT");

            $state.go("public.online.error");
        };


    // UTILS

        // format phone number
        function reformatPhone () {
            $scope.numInput = $document.find('#primaryPhone');
            if (typeof intlTelInputUtils !== 'undefined') {
                var currentText = $scope.numInput.intlTelInput("getNumber", intlTelInputUtils.numberFormat.INTERNATIONAL);
                if (typeof currentText === 'string') {
                    $scope.numInput.intlTelInput('setNumber', currentText);
                }
            }
        };

        function reschedule() {

            // store customer in case of any input
            OnlineReservationContext.storeCustomer(vm.customer);

            // store new reference
            OnlineReservationContext.storeReference("CONTACT");

            if("LEGACY" == vm.reservationPage) {
                $state.go("public.online.legacy");
            } else if("RESERVE" == vm.reservationPage){
                $state.go("public.online.reserve");
            } else {
                onError("Unknown reference value, expected LEGACY OR RESERVE, found:" + vm.reservationPage + ".")
            }
        }

    }

})();



