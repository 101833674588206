(function () {
    'use strict';

    angular
        .module('seatonApp')
        .controller('OnlineReservationSettingsController', OnlineReservationSettingsController);

    OnlineReservationSettingsController.$inject = ['$scope', '$state', '$document', 'SettingsResource', 'Log', '$uibModal', 'ApplicationContext', '$timeout', 'SweetAlert'];

    var DATE_FORMAT = 'YYYY-MM-DD';
    var DATE_PICKER_FORMAT = 'MMM D, YYYY';

    function OnlineReservationSettingsController($scope, $state, $document, SettingsResource, Log, $uibModal, ApplicationContext, $timeout, SweetAlert) {

        var vm = this;
        vm.currencySymbol = ApplicationContext.getCurrencySym();
        vm.option = {};
        vm.locationId = ApplicationContext.getLocationID();

        $document.ready(
            function () {

                // date picker settings
                vm.datePicker = $document.find('input[name="datePicker"]');
                vm.datePicker.daterangepicker(
                    {
                        singleDatePicker: true,
                        showDropdowns: true,
                        locale: {format: DATE_PICKER_FORMAT}
                    },
                    function (start, end, label) {
                        vm.selectedDay = start.format(DATE_FORMAT);
                        vm.disableUntil = moment(vm.selectedDay, DATE_FORMAT).format(DATE_PICKER_FORMAT);
                    }
                );
                vm.datePicker.val(vm.disableUntil);

                // slider settings
                vm.div_slider_transparency = $document.find('#slider_transparency');
                vm.div_slider_transparency.ionRangeSlider({
                    min: 0,
                    max: 90,
                    step: 1.00,
                    from: 0,
                    postfix: "%",
                    prettify: true,
                    grid: true,
                    decorate_both: true,
                    onChange: function (data) {
                        //vm.transparency = vm.slider_transparency.result.from;
                        vm.custo.transparency = data.from;
                        vm.updateBtnPreview();
                    }
                });
                vm.slider_transparency = vm.div_slider_transparency.data("ionRangeSlider");
                load();
            }
        );

        vm.openDatePicker = function () {
            vm.datePicker.focus();
        }

        function getBaseURL() {
            var baseUrl = window.location.protocol + '//' + window.location.hostname;
            if (window.location.port) {
                baseUrl += ':' + window.location.port;
            }
            return baseUrl;
        }

        function load() {
            SettingsResource.getOnlineReservationCustomization({}, onSuccessCustomisationDto, onErrorGetData);
            SettingsResource.getAvailabilityIntegrationScript({}, onIntegrationScriptFetched, onErrorGetData);
        }

        function onSuccessCustomisationDto(data) {
            vm.custo = data;
            vm.custo.transparency = vm.custo.transparency != null ? vm.custo.transparency : 0;
            if(vm.slider_transparency!=null) vm.slider_transparency.update({from: vm.custo.transparency});
            $timeout(function () {
                // ugly workaround since ng-change does not work with the chosen color picker
                $scope.$watch('[vm.custo.backgroundColor, vm.custo.textColor, vm.custo.backgroundColorHover, vm.custo.textColorHover, vm.custo.buttonLineColor]',
                    function () {
                        vm.updateBtnPreview();
                    }, true);
            }, 500);

            var baseUrl = getBaseURL();
            vm.hashedLocationId = data.hashedLocationId;
            vm.onlineResAnchor = baseUrl + '?channel=merchant_web#/public/online/reservation/' + data.hashedLocationId;
            vm.onlineResButton = '<button onClick="' + baseUrl + '?channel=merchant_web#/public/online/reservation/' + data.hashedLocationId + '" target="_blank" >MAKE A RESERVATION</button>';
            vm.onlineResLocal = '?channel=merchant_web#/public/online/reservation/' + data.hashedLocationId;
            vm.onlineResGoogleAnchor = baseUrl + '?channel=google#/public/online/reservation/' + data.hashedLocationId;
            vm.onlineResFacebookAnchor = baseUrl + '?channel=facebook#/public/online/reservation/' + data.hashedLocationId;
            vm.onlineResFacebookPost = "Book now using the following link: " + vm.onlineResFacebookAnchor;
            vm.updateWidgetPreview();
        }

        function onIntegrationScriptFetched(data) {
            vm.integrationScript = data.content;
            // inserting the script tag to the preview the floating button iframe
            if(document.getElementById('btnPreviewFrame')!=null) {
                var doc = document.getElementById('btnPreviewFrame').contentWindow.document;
                doc.open();
                doc.write(vm.integrationScript);
                doc.close();
            }
        }

        function onErrorGetData(error) {
            console.log("HTTP request error." + error);
        }

        vm.save = function () {
            // check if temporary disabled date and time are defined
            if(vm.custo.status=="1" && (vm.custo.disableUntilDate == undefined || vm.custo.disableUntilTime == undefined)) {
                SweetAlert.swal({
                        title: "Online reservation settings not saved.",
                        text: "Please define date and time until online reservation is disabled!",
                        type: "error",
                        showCancelButton: false,
                        confirmButtonColor: "#1ab394",
                        confirmButtonText: "OK",
                        closeOnConfirm: true
                    },
                    function (isConfirm) {
                    });
            } else {
                SettingsResource.saveOnlineReservationCustomization(
                    vm.custo,
                    function (data) {
                    }
                )
            }

        }


        vm.changeReservationPage = function() {
            if(vm.custo.reservationPage =="RESERVE") {
                SettingsResource.canActivateReservePage(
                    {},
                    function (data) {
                        var message = "";
                        var canSwitch = true;
                        if(data.seatingOptionExist==false) {
                            canSwitch = false;
                            message = "Please define at least one seating option before switching to NEW online reservation page!"
                        }
                        if(data.noOpenPreReservation==false) {
                            canSwitch = false;
                            message = message + "Please assign table or cancel all open pre-reservation event before switching to NEW online reservation page! "
                        }
                        if(!canSwitch) {
                            vm.custo.reservationPage="LEGACY"
                            SweetAlert.swal({
                                    title: "Switch to new reservation page not possible!",
                                    text: message,
                                    type: "warning",
                                    showCancelButton: false,
                                    confirmButtonColor: "#1ab394",
                                    confirmButtonText: "OK",
                                    closeOnConfirm: true
                                },
                                function (isConfirm) {
                                });
                        }
                    }
                );
            }

        }

        vm.loadSeatingOptions = function () {
            Log.debug("OnlineReservationSettingsController","enter seating option tab");
            SettingsResource.getSeatingOptionSettings({}, updateSeatingOptionSettings);
        }

        vm.saveSeatingOption = function() {
            vm.option.areaList = getSelectedAreas();
            SettingsResource.saveSeatingOption(vm.option, updateSeatingOptionSettings);
        }

        vm.editSeatingOption = function(option) {
            Log.debug("Seating controller","Seating option id "+option.id);
            vm.option = option;
            vm.option.areaList.forEach(
                function(area) {
                    area.selected = true;
                }
            );
            if(!vm.availableAreasOriginal) vm.availableAreasOriginal = [];
            vm.availableAreas = vm.availableAreasOriginal.concat(vm.option.areaList);
        }

        vm.deleteSeatingOption = function(option) {
            SweetAlert.swal({
                    title: "Confirm seating option deletion",
                    text: "Do you want to delete seating option from settings?",
                    type: "warning",
                    showCancelButton: true,
                    cancelButtonColor: "#ec4758",
                    confirmButtonColor: "#1ab394",
                    cancelButtonText: "No",
                    confirmButtonText: "Yes",
                    closeOnConfirm: true
                },
                function (isConfirm) {
                    if (isConfirm) {
                        SettingsResource.deleteSeatingOption({'id': option.id}, updateSeatingOptionSettings);
                    }
                });

        }

        vm.reOrderOption = function(sourceId, destinationId) {
            var reOrderIds = {
                'sourceId': sourceId,
                'destinationId': destinationId
            }

            SettingsResource.reOrderSeatingOption(reOrderIds, updateSeatingOptionSettings);

        }

        vm.validateInput = function() {
            if(!vm.option || !vm.option.name || !vm.option.label) return true;
            if (vm.availableAreas) {
                for (var i = 0; i < vm.availableAreas.length; i++) {
                    if (vm.availableAreas[i].selected === true) {
                        return false
                    }
                }
            }
            return true;
        }

        function updateSeatingOptionSettings(data) {
            vm.seatingOptions = data.seatingOptionList;
            vm.availableAreas = data.areas;
            vm.availableAreasOriginal = data.areas;
            vm.timeSlotSetList = data.timeSlotSetList;
            vm.option = {};
        }

        function getSelectedAreas() {
            var selectedAreaList = [];

            if (vm.availableAreas) {
                for (var i = 0; i < vm.availableAreas.length; i++) {
                    if (vm.availableAreas[i].selected === true) {
                        selectedAreaList.push(vm.availableAreas[i]);
                    }
                }
            }
            return selectedAreaList;
        }

        function setSelectedAreas(selectedAreaList) {

            if (vm.availableAreas) {
                for (var i = 0; i < vm.availableAreas.length; i++) {
                    if (vm.availableAreas[i].selected === true) {
                        areaIdList.push(vm.availableAreas[i]);
                    }
                }
            } else {
                vm.availableAreas =  selectedAreaList;
            }
            return areaIdList;
        }



        vm.enablePaymentAlert = function () {
            if (vm.custo.enablePayment) {
                var titleText = ' <b>Enabling online reservation fee - <br> action needed</b>  <br><br>';
                var url = "https://support.seaton.site/215805-How-to-reinstall-SeatOn-application"
                var htmlMessage = "Please note that if you have installed the SeatOn application <b>before November 3rd, 2020.</b>, " +
                    "reinstalling the application is necessary in order to use online reservation fees funcionality.<br>" +
                    " Reinstalling application ensures correct permissions for payment API. <br><br>" +
                    "  ";
                var htmlText = titleText + htmlMessage + "  <div class=\"widget \" style=\"background:#ccffcc\"><a target=\"_blank\" href=\"" + url + "\"> Please follow instructions on link:<br>" + url + "  <br></a></div> <br>";
                SweetAlert.swal({
                    html: true,
                    title: null,
                    text: htmlText,
                    type: "warning",
                    showCancelButton: false,
                    confirmButtonColor: "#DD6B55", confirmButtonText: "Close",
                    cancelButtonText: "Cancel",
                    closeOnConfirm: false,
                    closeOnCancel: false
                });
            }
        }

        vm.updateBtnPreview = function () {
            if(document.getElementById("btnPreviewFrame")!=null) {
                document.getElementById("btnPreviewFrame").
                    contentWindow["SeatOn" + ApplicationContext.getLocationID()].updatePreview('reservations', vm.custo);
            }
        }

        vm.updateWidgetPreview = function () {

            if (!vm.hashedLocationId) return;

            var baseUrl = getBaseURL();
            vm.widgetFrameSrc = baseUrl + "/content/widgets/widget.html?loc=" + vm.hashedLocationId + "&type=" + vm.custo.widgetType + "&bkg=" + vm.custo.widgetBackground;
            var width, height;
            if (vm.custo.widgetType === 'HORIZONTAL') {
                var width = vm.custo.enableManualDuration ? 1000 : 820;
                var height = 335;
                vm.inlineWidgetCode = '<iframe frameBorder="0" scrolling="no" width="' + width + '" height="' + height + '" src="' + baseUrl + '/content/widgets/widget.html?loc=' + vm.hashedLocationId + '&type=' + vm.custo.widgetType + '&bkg=' + vm.custo.widgetBackground + '"></iframe>';
            } else {  // vertical
                var width = 235;
                var height = vm.custo.enableManualDuration ? 350 : 335;
                vm.inlineWidgetCode = '<iframe frameBorder="0" scrolling="no" width="' + width + '" height="' + height + '" src="' + baseUrl + '/content/widgets/widget.html?loc=' + vm.hashedLocationId + '&type=' + vm.custo.widgetType + '&bkg=' + vm.custo.widgetBackground + '"></iframe>';
            }

            vm.widgetTypeHorizontalDesc = 'Horizontal (' + width + ' x ' + height + ' px)';
            vm.widgetTypeVerticalDesc = 'Vertical (' + width + ' x ' + height + ' px)';
        }

        vm.copyToClipboard = function (elementId) {
            var copyText = document.getElementById(elementId);
            copyText.disabled = false;
            copyText.select();
            document.execCommand("Copy");
            copyText.disabled = true;
        }

        vm.testAreas = [
            {id: 1, name: 'Jako dugi naziv area koji nece ...'},
            {id: 2, name: '2 hours'},
            {id: 3, name: '3 hours'},
            {id: 4, name: '4 hours'},
            {id: 6, name: '6 hours'},
            {id: 12, name: '12 hours'},
            {id: 14, name: '24 hours'}
        ];

        vm.maxDurationList = [
            {id: 1, name: '1 hour'},
            {id: 2, name: '2 hours'},
            {id: 3, name: '3 hours'},
            {id: 4, name: '4 hours'},
            {id: 6, name: '6 hours'},
            {id: 12, name: '12 hours'},
            {id: 14, name: '24 hours'}
        ];

        vm.minDurationList = [
            {id: 15, name: '15 min'},
            {id: 30, name: '30 min'},
            {id: 60, name: '1 hour'},
            {id: 120, name: '2 hours'}
        ];

        vm.timeStepList = [
            {id: 15, name: '15 min'},
            {id: 30, name: '30 min'},
            {id: 60, name: '1 hour'},
            {id: 90, name: '1 hour 30 min'},
            {id: 120, name: '2 hours'}
        ];

        vm.timeBetweenEventsist = [
            {id: 0, name: '0 min'},
            {id: 5, name: '5 min'},
            {id: 10, name: '10 min'},
            {id: 15, name: '15 min'},
            {id: 20, name: '20 min'},
            {id: 25, name: '25 min'},
            {id: 30, name: '30 min'},
            {id: 45, name: '45 min'},
            {id: 60, name: '1 hour'},
            {id: 90, name: '1 hour 30 min'},
            {id: 120, name: '2 hours'}
        ];


        vm.cancelTimeList = [
            {id: 0, name: 'any time'},
            {id: 30, name: '30 min'},
            {id: 60, name: '1 hour'},
            {id: 90, name: '1 hour 30 min'},
            {id: 120, name: '2 hours'},
            {id: 180, name: '3 hours'},
            {id: 240, name: '4 hours'},
            {id: 360, name: '6 hours'},
            {id: 720, name: '12 hours'},
            {id: 1440, name: '1 day'},
            {id: 2880, name: '2 days'},
            {id: 4320, name: '3 days'},
            {id: 5760, name: '4 days'},
            {id: 7200, name: '5 days'},
            {id: 8640, name: '6 days'},
            {id: 10080, name: '7 days'}
        ];

        vm.leadTime = [
            {id: 0, name: 'No lead time'},
            {id: 15, name: '15 min'},
            {id: 30, name: '30 min'},
            {id: 30, name: '45 min'},
            {id: 60, name: '1 hour'},
            {id: 90, name: '1 hour 30 min'},
            {id: 120, name: '2 hours'},
            {id: 150, name: '2 hours 30 min'},
            {id: 180, name: '3 hours'},
            {id: 240, name: '4 hours'},
            {id: 360, name: '6 hours'},
            {id: 480, name: '8 hours'},
            {id: 600, name: '10 hours'},
            {id: 720, name: '12 hours'},
            {id: 1440, name: '1 day'},
            {id: 2880, name: '2 days'}
        ];

        vm.maxReservationSlotList = [
            {id: -1, name: 'No restriction while available resources exist'},
            {id: 1, name: '1 reservation per time slot'},
            {id: 2, name: '2 reservations per time slot'},
            {id: 3, name: '3 reservations per time slot'},
            {id: 4, name: '4 reservations per time slot'},
            {id: 5, name: '5 reservations per time slot'},
            {id: 6, name: '6 reservations per time slot'},
            {id: 7, name: '7 reservations per time slot'},
            {id: 8, name: '8 reservations per time slot'},
            {id: 9, name: '9 reservations per time slot'},
            {id: 10, name: '10 reservations per time slot'},
            {id: 11, name: '11 reservations per time slot'},
            {id: 12, name: '12 reservations per time slot'},
            {id: 13, name: '13 reservations per time slot'},
            {id: 14, name: '14 reservations per time slot'},
            {id: 15, name: '15 reservations per time slot'},
            {id: 16, name: '16 reservations per time slot'},
            {id: 17, name: '17 reservations per time slot'},
            {id: 18, name: '18 reservations per time slot'},
            {id: 19, name: '19 reservations per time slot'},
            {id: 20, name: '20 reservations per time slot'},
            {id: 25, name: '25 reservations per time slot'},
            {id: 30, name: '30 reservations per time slot'},
            {id: 40, name: '40 reservations per time slot'},
            {id: 50, name: '50 reservations per time slot'},
            {id: 60, name: '60 reservations per time slot'},
            {id: 70, name: '70 reservations per time slot'},
            {id: 80, name: '80 reservations per time slot'},
            {id: 90, name: '90 reservations per time slot'},
            {id: 100, name: '100 reservations per time slot'}
        ];

        vm.maxReservationsSlotScopeList = [
            {id: 'ALL', name: 'consider all reservations.'},
            {id: 'CREATED_BY_CUSTOMER', name: 'consider only reservations created by customer online. '}
        ];

        vm.position_options = [
            {id: 1, name: 'left page edge'},
            {id: 2, name: 'right page edge'},
            {id: 3, name: 'bottom page edge'},
            {id: 4, name: 'top page edge'}
        ];

        vm.padding_options = [
            {id: 0, name: 'start of window'},
            {id: 25, name: '1/4 of window'},
            {id: 33, name: '1/3 of window'},
            {id: 50, name: '1/2 of window'},
            {id: 66, name: '2/3 of window'},
            {id: 75, name: '3/4 of window'},
            {id: 100, name: 'end of window'}
        ];

        vm.font_size = [
            {id: 8, name: '8px'},
            {id: 9, name: '9px'},
            {id: 10, name: '10px'},
            {id: 11, name: '11px'},
            {id: 12, name: '12px'},
            {id: 14, name: '14px'},
            {id: 16, name: '16px'},
            {id: 18, name: '18px'},
            {id: 20, name: '20px'},
            {id: 22, name: '22px'},
            {id: 24, name: '24px'}
        ];

        vm.maxSeats = [
            {id: 1, name: '1 persons'},
            {id: 2, name: '2 persons'},
            {id: 3, name: '3 persons'},
            {id: 4, name: '4 persons'},
            {id: 5, name: '5 persons'},
            {id: 6, name: '6 persons'},
            {id: 8, name: '8 persons'},
            {id: 10, name: '10 persons'},
            {id: 12, name: '12 persons'},
            {id: 14, name: '14 persons'},
            {id: 16, name: '16 persons'},
            {id: 20, name: '20 persons'},
            {id: 22, name: '22 persons'},
            {id: 24, name: '24 persons'},
            {id: 26, name: '26 persons'},
            {id: 28, name: '28 persons'},
            {id: 30, name: '30 persons'},
            {id: 40, name: '40 persons'},
            {id: 50, name: '50 persons'},
            {id: 75, name: '75 persons'},
            {id: 100, name: '100 persons'},
            {id: 150, name: '150 persons'}
        ];

        vm.status_options = [
            {id: 0, name: 'enabled.'},
            {id: 1, name: 'disabled until defined date and time.'},
            {id: 2, name: 'disabled.'}
        ];

        $scope.availableTimes = [
            {time: "End of day", timeForDB: "23:59"},
            {time: "12:00 AM", timeForDB: "00:00"},
            {time: "12:15 AM", timeForDB: "00:15"},
            {time: "12:30 AM", timeForDB: "00:30"},
            {time: "12:45 AM", timeForDB: "00:45"},
            {time: "1:00 AM", timeForDB: "01:00"},
            {time: "1:15 AM", timeForDB: "01:15"},
            {time: "1:30 AM", timeForDB: "01:30"},
            {time: "1:45 AM", timeForDB: "01:45"},
            {time: "2:00 AM", timeForDB: "02:00"},
            {time: "2:15 AM", timeForDB: "02:15"},
            {time: "2:30 AM", timeForDB: "02:30"},
            {time: "2:45 AM", timeForDB: "02:45"},
            {time: "3:00 AM", timeForDB: "03:00"},
            {time: "3:15 AM", timeForDB: "03:15"},
            {time: "3:30 AM", timeForDB: "03:30"},
            {time: "3:45 AM", timeForDB: "03:45"},
            {time: "4:00 AM", timeForDB: "04:00"},
            {time: "4:15 AM", timeForDB: "04:15"},
            {time: "4:30 AM", timeForDB: "04:30"},
            {time: "4:45 AM", timeForDB: "04:45"},
            {time: "5:00 AM", timeForDB: "05:00"},
            {time: "5:15 AM", timeForDB: "05:15"},
            {time: "5:30 AM", timeForDB: "05:30"},
            {time: "5:45 AM", timeForDB: "05:45"},
            {time: "6:00 AM", timeForDB: "06:00"},
            {time: "6:15 AM", timeForDB: "06:15"},
            {time: "6:30 AM", timeForDB: "06:30"},
            {time: "6:45 AM", timeForDB: "06:45"},
            {time: "7:00 AM", timeForDB: "07:00"},
            {time: "7:15 AM", timeForDB: "07:15"},
            {time: "7:30 AM", timeForDB: "07:30"},
            {time: "7:45 AM", timeForDB: "07:45"},
            {time: "8:00 AM", timeForDB: "08:00"},
            {time: "8:15 AM", timeForDB: "08:15"},
            {time: "8:30 AM", timeForDB: "08:30"},
            {time: "8:45 AM", timeForDB: "08:45"},
            {time: "9:00 AM", timeForDB: "09:00"},
            {time: "9:15 AM", timeForDB: "09:15"},
            {time: "9:30 AM", timeForDB: "09:30"},
            {time: "9:45 AM", timeForDB: "09:45"},
            {time: "10:00 AM", timeForDB: "10:00"},
            {time: "10:15 AM", timeForDB: "10:15"},
            {time: "10:30 AM", timeForDB: "10:30"},
            {time: "10:45 AM", timeForDB: "10:45"},
            {time: "11:00 AM", timeForDB: "11:00"},
            {time: "11:15 AM", timeForDB: "11:15"},
            {time: "11:30 AM", timeForDB: "11:30"},
            {time: "11:45 AM", timeForDB: "11:45"},
            {time: "12:00 PM", timeForDB: "12:00"},
            {time: "12:15 PM", timeForDB: "12:15"},
            {time: "12:30 PM", timeForDB: "12:30"},
            {time: "12:45 PM", timeForDB: "12:45"},
            {time: "1:00 PM", timeForDB: "13:00"},
            {time: "1:15 PM", timeForDB: "13:15"},
            {time: "1:30 PM", timeForDB: "13:30"},
            {time: "1:45 PM", timeForDB: "13:45"},
            {time: "2:00 PM", timeForDB: "14:00"},
            {time: "2:15 PM", timeForDB: "14:15"},
            {time: "2:30 PM", timeForDB: "14:30"},
            {time: "2:45 PM", timeForDB: "14:45"},
            {time: "3:00 PM", timeForDB: "15:00"},
            {time: "3:15 PM", timeForDB: "15:15"},
            {time: "3:30 PM", timeForDB: "15:30"},
            {time: "3:45 PM", timeForDB: "15:45"},
            {time: "4:00 PM", timeForDB: "16:00"},
            {time: "4:15 PM", timeForDB: "16:15"},
            {time: "4:30 PM", timeForDB: "16:30"},
            {time: "4:45 PM", timeForDB: "16:45"},
            {time: "5:00 PM", timeForDB: "17:00"},
            {time: "5:15 PM", timeForDB: "17:15"},
            {time: "5:30 PM", timeForDB: "17:30"},
            {time: "5:45 PM", timeForDB: "17:45"},
            {time: "6:00 PM", timeForDB: "18:00"},
            {time: "6:15 PM", timeForDB: "18:15"},
            {time: "6:30 PM", timeForDB: "18:30"},
            {time: "6:45 PM", timeForDB: "18:45"},
            {time: "7:00 PM", timeForDB: "19:00"},
            {time: "7:15 PM", timeForDB: "19:15"},
            {time: "7:30 PM", timeForDB: "19:30"},
            {time: "7:45 PM", timeForDB: "19:45"},
            {time: "8:00 PM", timeForDB: "20:00"},
            {time: "8:15 PM", timeForDB: "20:15"},
            {time: "8:30 PM", timeForDB: "20:30"},
            {time: "8:45 PM", timeForDB: "20:45"},
            {time: "9:00 PM", timeForDB: "21:00"},
            {time: "9:15 PM", timeForDB: "21:15"},
            {time: "9:30 PM", timeForDB: "21:30"},
            {time: "9:45 PM", timeForDB: "21:45"},
            {time: "10:00 PM", timeForDB: "22:00"},
            {time: "10:15 PM", timeForDB: "22:15"},
            {time: "10:30 PM", timeForDB: "22:30"},
            {time: "10:45 PM", timeForDB: "22:45"},
            {time: "11:00 PM", timeForDB: "23:00"},
            {time: "11:15 PM", timeForDB: "23:15"},
            {time: "11:30 PM", timeForDB: "23:30"},
            {time: "11:45 PM", timeForDB: "23:45"}
        ];

        vm.automationStrategyList = [
            {id: 'PERFECT_MATCH_ONLY', name: 'Assign one table with matched number of seats, if not found leave unassigned.'},
            {id: 'PERFECT_MATCH_OR_BIGGER', name: 'Assign any table to accommodate request, if not found leave unassigned.'},
            {id: 'PERFECT_MATCH_OR_BIGGER_THEN_MERGE_ALL', name: 'Assign any table to accommodate request, if one is not sufficient merge more tables (in all Areas).'},
            {id: 'PERFECT_MATCH_OR_BIGGER_THEN_MERGE_SAME_AREA', name: 'Assign any table to accommodate request, if one is not sufficient merge more tables (only within same Area).'}
        ];

        vm.paymentBehaviorList = [
            {id: 'RESERVATION', name: 'Fee per reservation'},
            {id: 'COVER', name: 'Fee per person'}
        ];

        vm.reservationDateLimit = [
            {id: -1, name: 'no limitation'},
            {id: 3, name: '3 days'},
            {id: 7, name: '1 week'},
            {id: 10, name: '10 days'},
            {id: 14, name: '2 weeks'},
            {id: 20, name: '3 weeks'},
            {id: 30, name: '1 month'},
            {id: 60, name: '2 months'},
            {id: 90, name: '3 months'},
            {id: 120, name: '4 months'},
            {id: 180, name: '6 months'},
            {id: 365, name: '1 year'}
        ];


        $scope.closeReservationDetailDialog = function () {
            vm.reservationPreview.dismiss('cancel');
        }

        $scope.showReservationDetailDialog = function () {
            vm.reservationPreview = $uibModal.open({
                templateUrl: 'app/settings/online/reservation/preview.details.preview.html',
                scope: $scope,
                size: 'lg'
            });
        }

        $scope.closeReservationRequestDialog = function () {
            vm.reservationPreview.dismiss('cancel');
        }

        $scope.showReservationRequestDialog = function () {
            vm.reservationPreview = $uibModal.open({
                templateUrl: 'app/settings/online/reservation/preview.request.preview.html',
                scope: $scope,
                size: 'lg'
            });
        }

        $scope.closeReservationPaymentDialog = function () {
            vm.reservationPreview.dismiss('cancel');
        }

        $scope.showReservationPaymentDialog = function () {
            vm.reservationPreview = $uibModal.open({
                templateUrl: 'app/settings/online/reservation/preview.payment.preview.html',
                scope: $scope,
                size: 'lg'
            });
        }

        $scope.closeReservationCompletedDialog = function () {
            vm.reservationPreview.dismiss('cancel');
        }

        $scope.showReservationCompletedDialog = function () {
            vm.reservationPreview = $uibModal.open({
                templateUrl: 'app/settings/online/reservation/preview.completed.preview.html',
                scope: $scope,
                size: 'lg'
            });
        }

        // When the user clicks on the button, scroll to the top of the document
        vm.scrollToTop = function() {
            document.body.scrollTop = 0; // For Safari
            document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        }


    }
})();



