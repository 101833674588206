(function () {
    'use strict';

    angular
        .module('seatonApp')
        .controller('TimeSlotMigrationController', TimeSlotMigrationController);

    TimeSlotMigrationController.$inject = ['$rootScope', '$state', 'MigrationService', 'Notification', 'ParseLinks'];

    function TimeSlotMigrationController($rootScope, $state, MigrationService, Notification, ParseLinks) {
        var vm = this;

        vm.migrateOne = function(id) {
            if(id) vm.locationIdToMigrate = id;
            if(vm.locationIdToMigrate) {

                var params = {
                    'locationId': vm.locationIdToMigrate
                };

                MigrationService.migrateOneTimeSlot(params, onOneMigrateSuccess, onError);

            } else {
                Notification.warning("Please define location id to migrate.");
            }

            function onOneMigrateSuccess(data, headers) {
                //Notification.info("Migration finished successfully, please check time slot definition.");
            }
        }

        vm.migrateAll = function() {

            var params = {
                'locationId': vm.locationIdToMigrate
            };

            MigrationService.migrateAllTimeSlot(params, onAllMigrateSuccess, onError);

            function onAllMigrateSuccess(data, headers) {
                    vm.resultList = data;
                    Notification.info("Migration finished. Please check migration result table.");
            }

        }

        function onError(error) {
            Notification.error("Migration failed. Error = " + error.data.message);
        }

    }
})();
