'use strict';

ReservationYearViewController.$inject = ['$state', '$stateParams', '$location', '$scope', '$q', '$filter', '$document', '$timeout',
    'Tables', 'ApplicationContext', '$uibModal', 'TextUtil', 'EventResource', 'SweetAlert'];

function ReservationYearViewController($state, $stateParams, $location, $scope, $q, $filter, $document, $timeout,
                                       Tables, ApplicationContext, $uibModal, TextUtil, EventResource, SweetAlert) {

    var vm = this;
    var today = new Date();
    var yesterday = new Date(today.setDate(today.getDate() - 1));

    var currentYear = new Date().getFullYear();

    EventResource.overview({}, onSuccess, onError);

    function onError(error) {
        Log.debug("tables year calendar", "HTTP request error." + error);
    }

    function onSuccess(data, headers) {
        var text = JSON.stringify(data);

        const dataObj = JSON.parse(text, function (key, value) {
            if (key == "startDate" || key == "endDate") {
                //console.log(new Date(value))
                //console.log(moment(value, "YYYY-MM-DD").toDate());
                return moment(value, "YYYY-MM-DD").toDate();
            } else {
                return value;
            }
        });

        var yearview = new Calendar('#yearview', {
            minDate: yesterday,
            clickDay: function (e) {
                //use moment timezone, not from browser(year view component)
                var dateClicked = moment("" + e.date.getFullYear() + "-" + (e.date.getMonth() + 1) + "-" + e.date.getDate(), "YYYY-M-D").clone().tz(moment().tz());
                console.log("timezone offset: " + dateClicked.format('Z') + "  timezone : " + moment().tz());

                var params = {
                    range: 'custom',
                    from: dateClicked.clone().startOf('day').toISOString(),
                    to: dateClicked.clone().endOf('day').toISOString()
                };

                if (e.events.length > 0) {
                    $(e.element).popover('hide');
                }

                var go = 'NONE';
                if (e.events.length > 0) {
                    var event = e.events[0];
                    if (event.reservationEventCount > 0 && event.preReservationEventCount > 0) {
                        var go = 'CHOICE';
                    } else if (event.reservationEventCount > 0) {
                        var go = 'CENTER';
                    } else {
                        var go = 'PRE-RESERVE';
                    }
                }
                showChoice(params, go)

            },
            mouseOnDay: function (e) {
                if (e.events.length > 0) {
                    var content = '';
                    var event = e.events[0];

                    if (!event.reservationEventCount == '0') {
                        content =
                            '<div class="event-tooltip-content" style="padding-bottom: 6px">'
                            + '<div class="event-name" style="background-color: #e2ecea">Reservations</div>'
                            + '<div class="event-location">' + event.reservationEventCount + ' event(s), ' + event.reservationCoverCount + ' cover(s)</div>'
                            + '</div>';
                    }
                    if (!event.preReservationEventCount == '0') {
                        content += '<div class="event-tooltip-content">'
                            + '<div class="event-name" style="background-color: #fff3cd"> Pre-reservations</div>'
                            + '<div class="event-location">' + event.preReservationEventCount + ' event(s), ' + event.preReservationCoverCount + ' cover(s)</div>'
                            + '</div>';
                    }

                    $(e.element).popover({
                        placement: 'bottom',
                        trigger: 'manual',
                        container: 'body',
                        html: true,
                        content: content
                    });

                    $(e.element).popover('show');
                }
            },
            mouseOutDay: function (e) {
                if (e.events.length > 0) {
                    $(e.element).popover('hide');
                }
            },
            dataSource: dataObj

        });
    }

    function showChoice(params, go) {
        if (go == 'CENTER') {
            $state.go("tables.overview", params);
        } else if (go == 'PRE-RESERVE') {
            $state.go("tables.management", {tab: 'online-res'});
        } else if (go == 'CHOICE') {
            SweetAlert.swal({
                    title: "Please confirm action",
                    text: "Please select where you want to go.",
                    type: "info",
                    showCancelButton: true,
                    confirmButtonColor: "#f8ac59",
                    cancelButtonColor: "#1ab394",
                    confirmButtonText: "Open pre-reservation list",
                    cancelButtonText: "Open reservation center",
                    closeOnConfirm: true
                },
                function (isConfirm) {
                    if (isConfirm) {
                        $state.go("tables.management", {tab: 'online-res'});
                    } else {
                        $state.go("tables.overview", params);
                    }
                });
        }
    }

}

angular.module('seatonApp').controller('ReservationYearViewController', ReservationYearViewController);
