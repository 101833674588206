(function () {
    'use strict';
    angular.module('seatonApp').controller('OnlineModifierController', OnlineModifierController);

    OnlineModifierController.$inject = ['$rootScope', '$scope', '$state', '$document', '$stateParams', '$filter',
        'Log', 'PublicResource','OnlineReservationContext', 'TextUtil', 'Utils'];

    function OnlineModifierController($rootScope, $scope, $state, $document, $stateParams, $filter,
                                      Log, PublicResource, OnlineReservationContext, TextUtil, Utils) {

    // INITIALIZE
        var vm = this;
        vm.fees = {};
        vm.fees.serviceCharge = 20; // default service charge / tip fee
        vm.minimumModiferAmountError=false;

        vm.DATE_FORMAT = 'YYYY-MM-DD';
        vm.TIME_SERVER_FORMAT = 'HH:mm:ss.SSS';
        vm.TIME_DISPLAY_FORMAT = 'hh:mmA';
        vm.moment = moment;

        vm.inputErrorOccured = false;
        vm.actionSubmitted = false;

        //vm.resourceNotAvailableAnymore = false;
        //vm.productChosenByGuest = false;
        //vm.productList = [];

        vm.resourceText = TextUtil.translate("resource","s");

        // FUNCTIONS definition
        vm.minimumModifierAmountDefined = minimumModifierAmountDefined;
        vm.formatAmount = formatAmount;
        vm.reschedule = reschedule;
        vm.adjustModifierNumber = adjustModifierNumber;
        vm.isModifierListWithAddedNumberIsEmpty = isModifierListWithAddedNumberIsEmpty;
        vm.calculateReservationFee = calculateReservationFee;
        vm.calculateTotalModifierFee = calculateTotalModifierFee;
        vm.calculateServiceChargeFee = calculateServiceChargeFee;
        vm.calculateTotalFee = calculateTotalFee;
        vm.setServiceCharge = setServiceCharge;
        vm.clear = clear;
        vm.proceedToContactPage = proceedToContactPage;
        vm.serviceChargeAllowed = serviceChargeAllowed;
        vm.isBamboo = isBamboo;

        //vm.makeReservation = makeReservation;
        //vm.inputNOK = inputNOK;
        //vm.resetSecondPage = resetSecondPage;
        //vm.secondPageActionButtonText = secondPageActionButtonText;
        //vm.inputErrorOccurs = inputErrorOccurs;
        //vm.reformatPhone = reformatPhone;
        //vm.setAndValidateParameters = setAndValidateParameters;

        // PARAMETERS AND SETTINGS
        vm.locationId = OnlineReservationContext.getLocationId();
        Log.debug("OnlineContactController", "Hashed locationId id is '" + vm.locationId + "'");

        //if (!vm.locationId) {
        //    // if location id not return to reservation proxy
        //    $state.go("public.online.reservation");
        //}
        vm.request = OnlineReservationContext.getRequest();
        //vm.customer = OnlineReservationContext.getCustomer();
        //vm.channel = OnlineReservationContext.getChannel();
        vm.reference = OnlineReservationContext.getReference();
        vm.reservationPage = OnlineReservationContext.getReservationPage();
        vm.settings = OnlineReservationContext.getSettings();

        if(!serviceChargeAllowed()) vm.fees.serviceCharge = 0; // if service charge not allowed for product, set it to 0


        // fetch modifiers for chosen product
        PublicResource.getProductModifiers(
            {
                hashedLocationId: vm.locationId,
                productId: vm.request.product.id
            },
            function (data) {
                Log.debug("OnlineModifierController","ModifierList="+ JSON.stringify(data) + ":");
                vm.modifierList = data;
            },
            onError
        );

        // FUNCTIONS
        function proceedToContactPage() {

            // Bamboo specific, if number of party size not equals to number of specified modifiers
            if(isBamboo()) {
                if(vm.modifierList && calculateTotalModifierNumber()!=vm.request.party_size) {
                    return;
                }
            }

            if (vm.request.product.minModifierAmount > vm.fees.totalModifiers) {
                vm.minimumModiferAmountError=true;
            } else {
                vm.minimumModiferAmountError=false;
                // store data to context
                OnlineReservationContext.storeProductModifiers(vm.modifierList);
                OnlineReservationContext.storeFees(vm.fees);

                // go to state contact
                $state.go("public.online.contact");
            }
        }

        function adjustModifierNumber(index, number) {
            if(index>-1) {
                vm.modifierList[index].number += number;
                if(vm.modifierList[index].number<0) vm.modifierList[index].number = 0;
            }
        }

        function minimumModifierAmountDefined() {
            if(vm.request && vm.request.product) {
                if(vm.request.product.minModifierAmount > 0) {
                    return true;
                }
            }
            return false;
        }

        function serviceChargeAllowed() {
            if(vm.request && vm.request.product) {
                return vm.request.product.allowServiceCharge;

            }
            return false;
        }

        function formatAmount(amount) {
            if(!amount) amount = 0;
            return Utils.formatAmount(amount);
        }

        function isModifierListWithAddedNumberIsEmpty() {
            var found = false;
            if(vm.modifierList) {
                for(var i = 0; i < vm.modifierList.length; i++) {
                    if(vm.modifierList[i].number > 0) {
                        found = true; break;
                    }
                }
            }
            return found;
        }

        function findModifier(modifierId) {
            if(vm.modifierList) {
                for(var i = 0; i < vm.modifierList.length; i++) {
                    if(vm.modifierList[i].id === modifierId) {
                        return i;
                    }
                }
            }
            return -1;
        }

        function calculateReservationFee() {
            vm.fees.reservation = 0;
            if(vm.request.product.rule=="COVER") {
                vm.fees.reservation = vm.request.party_size * vm.request.product.fee;
            } else {
                vm.fees.reservation = vm.request.product.fee;
            }
            return vm.fees.reservation;
        }

        function calculateTotalModifierFee() {
            vm.fees.totalModifiers = 0;
            if(vm.modifierList) {
                for (var i = 0; i < vm.modifierList.length; i++) {
                    vm.fees.totalModifiers+= vm.modifierList[i].fee * vm.modifierList[i].number;
                }
            }
            if(vm.minimumModiferAmountError) {
                if(vm.request.product.minModifierAmount <= vm.fees.totalModifiers) {
                    vm.minimumModiferAmountError = false;
                }
            }
            return vm.fees.totalModifiers;
        }

        function calculateTotalWithoutServiceChargeFee() {
            vm.fees.totalWithoutServiceCharge = 0;
            if(vm.fees.reservation) vm.fees.totalWithoutServiceCharge+=vm.fees.reservation;
            if(vm.fees.totalModifiers) vm.fees.totalWithoutServiceCharge+=vm.fees.totalModifiers;
            return vm.fees.totalWithoutServiceCharge;
        }

        function calculateServiceChargeFee() {
            vm.fees.serviceChargeFee = calculateTotalWithoutServiceChargeFee() * vm.fees.serviceCharge/100;
            return vm.fees.serviceChargeFee;
        }

        function calculateTotalFee() {
            vm.fees.total = calculateTotalWithoutServiceChargeFee() + calculateServiceChargeFee();
            return vm.fees.total;
        }

        function setServiceCharge(serviceCharge) {
            vm.fees.serviceCharge = serviceCharge;
            Log.debug("","vm.fees.serviceCharge = "+vm.fees.serviceCharge+", serviceCharge="+serviceCharge);
        }

        function calculateTotalModifierNumber() {
            vm.totalModifiersNumber = 0;
            if(vm.modifierList) {
                for (var i = 0; i < vm.modifierList.length; i++) {
                    vm.totalModifiersNumber+=vm.modifierList[i].number;
                }
            }
            return vm.totalModifiersNumber;
        }

        function clear() {
            if(vm.modifierList) {
                for (var i = 0; i < vm.modifierList.length; i++) {
                    vm.modifierList[i].number = 0;
                }
            }
        }

        vm.serviceChargeOtherList = [
            {name: '50%', value: 50},
            {name: '45%', value: 45},
            {name: '40%', value: 40},
            {name: '35%', value: 35},
            {name: '30%', value: 30},
            {name: '10%', value: 10},
            {name: '5%', value: 5},
            {name: '0%', value: 0}
        ];

        function isBamboo() {
            if(vm.locationId=="7EDE32B8") return true;
            return false;
        }


        //vm.setAndValidateParameters();

        // set parameters
        function setAndValidateParameters() {

            if(!vm.channel || !vm.request || !vm.reference || !vm.settings || !vm.reservationPage) {
                Log.debug("OnlineContactController","One of parameter is not defined, go to first step in process.");

                // store new reference
                OnlineReservationContext.storeReference("CONTACT");

                $state.go("public.online.reservation");
                return;
            }

            vm.phoneRegion = vm.settings.phoneRegion;
            vm.phonePrefix = vm.settings.phonePrefix;
            vm.productChosenByGuest = vm.settings.productChosenByGuest;

            if(!vm.customer) vm.customer = {};

            var localeApp = 'en';
            if (vm.phoneRegion == 'US') {
                localeApp = 'en';
                $rootScope.currencyIcon = 'fa fa-dollar';
            } else if (vm.phoneRegion == 'GB') {
                localeApp = 'en-gb';
                $rootScope.currencyIcon = 'fa fa-gbp';
            } else if (vm.phoneRegion == 'IE') {
                localeApp = 'en-ie';
                $rootScope.currencyIcon = 'fa fa-euro';
            } else if (vm.phoneRegion == 'CA') {
                localeApp = 'en-ca';
                $rootScope.currencyIcon = 'fa fa-money';
            }

        };

        // make reservation or proceed to payment page if payment enabled
        function makeReservation(phoneValid) {

            if (vm.inputNOK(phoneValid)) {
                vm.inputErrorOccured = true;
                Log.debug("Input not ok");
                return;
            } else {
                vm.inputErrorOccured = false;
            }

            // disable create button, so that the user does not accidentally send two requests
            vm.actionSubmitted = true;

            Log.debug("OnlineContactController: Payment enabled " +vm.settings.enablePayment+ ".","ONLINE Reservation");
            if(vm.settings.enablePayment) {

                var productId = null;
                if(vm.request.product) {
                    productId = vm.request.product.id;
                }
                PublicResource.getPaymentSettings(
                    {
                        hashedLocationId: vm.locationId
                    },
                    {
                        // reservation requested params
                        requestedDate: vm.request.date,
                        requestedTime: vm.request.time,
                        numberOfPersons: vm.request.party_size,
                        containerId: vm.request.containerId,
                        productId: productId,
                        productType: vm.settings.productType
                    },
                    function (data) {
                        Log.debug("OnlineContactController","Public key fetch, status="+ data.status +": key="+data.key+":");
                        vm.actionSubmitted = false;
                        if(data.status<0) {
                            onError("Public key not fetched from Clover.");
                        } else {
                            vm.customer.occasion = 1;
                            OnlineReservationContext.storeCustomer(vm.customer);
                            OnlineReservationContext.storePaymentSettings(data);

                            // store new reference
                            OnlineReservationContext.storeReference("CONTACT");

                            // if payment enabled go to payment page
                            $state.go("public.online.payment");
                        }
                    },
                    onError
                );

            } else {
                // if payment not enabled complete reservation at this step
                PublicResource.prereserve(
                    {
                        hashedLocationId: vm.locationId
                    },
                    {
                        // reservation requested params
                        requestedDate: vm.request.date,
                        requestedTime: vm.request.time,
                        containerId: vm.request.containerId,
                        containerName: vm.request.containerName,
                        numberOfPersons: vm.request.party_size,
                        product: vm.request.product,
                        // other
                        productChosenByGuest: vm.settings.productChosenByGuest,
                        productType: vm.settings.productType,
                        // customer details
                        firstName: vm.customer.firstName,
                        lastName: vm.customer.lastName,
                        email: vm.customer.primaryEmail,
                        phone: vm.customer.primaryPhone,
                        note: vm.customer.note,
                        // other
                        channel: vm.channel,
                        occasion: 1
                    },
                    function (data) {

                        vm.resourceNotAvailableAnymore = data.resourceNotAvailable;
                        vm.actionSubmitted = false;

                        if (vm.resourceNotAvailableAnymore == false) {
                            OnlineReservationContext.storeCustomer(vm.customer);
                            OnlineReservationContext.storeVerificationSent(data.verificationSent);

                            // store new reference
                            OnlineReservationContext.storeReference("CONTACT");

                            $state.go("public.online.success");
                        }
                    },
                    onError
                );
            }
        };

        // validate second screen input
        function inputNOK (phoneValid) {
            var input =
                !(vm.request.party_size > 0) ||
                !vm.customer ||
                !vm.customer.firstName ||
                !vm.customer.lastName ||
                !vm.customer.primaryEmail ||
                !(vm.customer.primaryPhone && phoneValid);

            return input;
        };

        // reset second page form
        function resetSecondPage () {
            // workaround for https://github.com/angular/angular.js/issues/2743
            jQuery("input[name='primaryEmail']").val("");
            jQuery("input[name='primaryPhone']").val("");
            vm.customer = null;
            vm.inputErrorOccured = false;
        };

        // action button text depends on payment enabled
        function secondPageActionButtonText() {
            if(vm.settings.enablePayment) {
                return "Proceed to payment page"
            } else {
                return "Complete reservation";
            }
        };


    // HANDLE ERRORS

        function inputErrorOccurs () {
            return vm.inputErrorOccured;
        };

        function onError(error) {
            Log.debug("OnlineCreateLegacyController", "Unexpected error:" + error + ".");
            vm.actionSubmitted = false;

            // store new reference
            OnlineReservationContext.storeReference("CONTACT");

            $state.go("public.online.error");
        };


    // UTILS

        // format phone number
        function reformatPhone () {
            $scope.numInput = $document.find('#primaryPhone');
            if (typeof intlTelInputUtils !== 'undefined') {
                var currentText = $scope.numInput.intlTelInput("getNumber", intlTelInputUtils.numberFormat.INTERNATIONAL);
                if (typeof currentText === 'string') {
                    $scope.numInput.intlTelInput('setNumber', currentText);
                }
            }
        };

        function reschedule() {

            // store customer in case of any input
            OnlineReservationContext.storeCustomer(vm.customer);

            // store new reference
            OnlineReservationContext.storeReference("CONTACT");

            if("LEGACY" == vm.reservationPage) {
                $state.go("public.online.legacy");
            } else if("RESERVE" == vm.reservationPage){
                $state.go("public.online.reserve");
            } else {
                onError("Unknown reference value, expected LEGACY OR RESERVE, found:" + vm.reservationPage + ".")
            }
        }

    }

})();



