(function () {
    'use strict';
    angular.module('seatonApp').controller('SetupController', SetupController);

    SetupController.$inject = ['$rootScope', '$scope', '$state', '$document', '$stateParams', 'Log', 'PublicResource', 'TextUtil', 'ListUtils', 'SweetAlert'];

    function SetupController($rootScope, $scope, $state, $document, $stateParams, Log, PublicResource, TextUtil, ListUtils, SweetAlert) {

    // INITIALIZE
        var vm = this;

        vm.DATE_FORMAT = 'YYYY-MM-DD';
        vm.TIME_SERVER_FORMAT = 'HH:mm:ss.SSS';
        vm.TIME_DISPLAY_FORMAT = 'hh:mmA';
        vm.moment = moment;

        vm.availableTimes = ListUtils.getAvailableTimesForDay30MinStep();
        vm.availableTimesForDuration = ListUtils.getAvailableTimesForEventDuration();

        vm.setup = {};
        vm.setup.timeStep=30;
        vm.setup.minParty=1;
        vm.setup.maxParty=6;
        vm.setup.automationPolicy='PERFECT_MATCH_ONLY';
        vm.setup.reservationPage="RESERVE";
        vm.setup.breakfastName="Breakfast";
        vm.setup.breakfastEnd="11:00";
        vm.setup.breakfastDuration=45;
        vm.setup.lunchName="Lunch";
        vm.setup.lunchEnd="17:00";
        vm.setup.lunchDuration=90;
        vm.setup.dinnerName="Dinner";
        vm.setup.dinnerDuration=120;
        vm.setup.minDuration=30;
        vm.setup.maxDuration=4;
        vm.setup.timeSlotOption='SIMPLE';
        vm.setup.floorPlanOption='PICTURE';
        vm.setup.durationOption='MEAL';

        vm.setup.merchantId = $stateParams.merchantId;
        Log.debug("Setup", "merchantId:"+vm.setup.merchantId);

        vm.merchantNotDefined = function() {
            if(vm.setup.merchantId) return false;
            return true;
        }

        vm.submitQ = function() {

            PublicResource.submitQuestionnaire(
                {
                    setup: vm.setup
                }, onSuccess, onError);


            function onSuccess() {
                $state.go("public.setup.success")
            }

            function onError() {
                /*SweetAlert.swal({
                    html: true,
                    title: null,
                    text: "Error occurred. Please try again in few minutes or send us this questionnaire on email: support@seaton.site",
                    type: "error",
                    showCancelButton: false,
                    confirmButtonColor: "#DD6B55", confirmButtonText: "Close",
                    closeOnConfirm: false
                });*/

            }


        }

        vm.timeStepList = [
            {id: 15, name: '15 min'},
            {id: 30, name: '30 min'},
            {id: 60, name: '1 hour'},
            {id: 90, name: '1 hour 30 min'},
            {id: 120, name: '2 hours'}
        ];

        vm.partySize = [
            {id: 1, name: '1 persons'},
            {id: 2, name: '2 persons'},
            {id: 3, name: '3 persons'},
            {id: 4, name: '4 persons'},
            {id: 5, name: '5 persons'},
            {id: 6, name: '6 persons'},
            {id: 8, name: '8 persons'},
            {id: 10, name: '10 persons'},
            {id: 12, name: '12 persons'},
            {id: 14, name: '14 persons'},
            {id: 16, name: '16 persons'},
            {id: 20, name: '20 persons'},
            {id: 30, name: '30 persons'},
            {id: 40, name: '40 persons'},
            {id: 50, name: '50 persons'},
            {id: 100, name: '100 persons'}
        ];

        vm.automationStrategyList = [
            {id: 'NO_ASSIGNMENT', name: 'Manual assignment'},
            {id: 'PERFECT_MATCH_ONLY', name: 'Automatic assignment: Assign one table with matched number of seats, if not found leave unassigned.'},
            {id: 'PERFECT_MATCH_OR_BIGGER', name: 'Automatic assignment: Assign any table to accommodate request, if not found leave unassigned.'},
            {id: 'PERFECT_MATCH_OR_BIGGER_THEN_MERGE_ALL', name: 'Automatic assignment: Assign any table to accommodate request, if one is not sufficient merge more tables (in all Areas).'},
            {id: 'PERFECT_MATCH_OR_BIGGER_THEN_MERGE_SAME_AREA', name: 'Automatic assignment: Assign any table to accommodate request, if one is not sufficient merge more tables (only within same Area).'}
        ];

        vm.maxDurationList = [
            {id: 1, name: '1 hour'},
            {id: 2, name: '2 hours'},
            {id: 3, name: '3 hours'},
            {id: 4, name: '4 hours'},
            {id: 6, name: '6 hours'},
            {id: 12, name: '12 hours'},
            {id: 14, name: '24 hours'}
        ];

        vm.minDurationList = [
            {id: 15, name: '15 min'},
            {id: 30, name: '30 min'},
            {id: 60, name: '1 hour'},
            {id: 120, name: '2 hours'}
        ];



    }

})();



