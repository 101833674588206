(function () {
    'use strict';

    angular
        .module('seatonApp')
        .controller('PaymentController', PaymentController);

    PaymentController.$inject = ['$rootScope', '$state', '$document', 'Notification', 'PaymentService'];

    function PaymentController($rootScope, $state, $document, Notification, PaymentService) {
        var vm = this;


        const clover = new Clover('e1ab02b0377aa83c0f9fa57b13a289e7');
        const elements = clover.elements();
        const styles = {
            body: {
                fontFamily: 'Roboto, Open Sans, sans-serif',
                fontSize: '16px',
            },
            input: {
                fontSize: '16px',
            },
        };
        const cardNumber = elements.create('CARD_NUMBER', styles);
        const cardDate = elements.create('CARD_DATE', styles);
        const cardCvv = elements.create('CARD_CVV', styles);
        const cardPostalCode = elements.create('CARD_POSTAL_CODE', styles);

        cardNumber.mount('#card-number');
        cardDate.mount('#card-date');
        cardCvv.mount('#card-cvv');
        cardPostalCode.mount('#card-postal-code');

        const cardResponse = document.getElementById('card-response');

        const displayCardNumberError = document.getElementById('card-number-errors');
        const displayCardDateError = document.getElementById('card-date-errors');
        const displayCardCvvError = document.getElementById('card-cvv-errors');
        const displayCardPostalCodeError = document.getElementById('card-postal-code-errors');

        // Handle real-time validation errors from the card Element.
        cardNumber.addEventListener('change', function(event) {
            console.log("cardNumber changed ${JSON.stringify(event)}");
        });

        cardNumber.addEventListener('blur', function(event) {
            console.log("cardNumber blur ${JSON.stringify(event)}");
        });

        cardDate.addEventListener('change', function(event) {
            console.log("cardDate changed ${JSON.stringify(event)}");
        });

        cardDate.addEventListener('blur', function(event) {
            console.log("cardDate blur ${JSON.stringify(event)}");
        });

        cardCvv.addEventListener('change', function(event) {
            console.log("cardCvv changed ${JSON.stringify(event)}");
        });

        cardCvv.addEventListener('blur', function(event) {
            console.log("cardCvv blur ${JSON.stringify(event)}");
        });

        cardPostalCode.addEventListener('change', function(event) {
            console.log("cardPostalCode changed ${JSON.stringify(event)}");
        });

        cardPostalCode.addEventListener('blur', function(event) {
            console.log("cardPostalCode blur ${JSON.stringify(event)}");
        });

        // TODO: Register event handlers
        const form = document.getElementById('payment-form');
        form.addEventListener('submit', function(event) {
            event.preventDefault();

            clover.createToken()
                .then(function(result) {
                    if (result.errors) {
                        Object.values(result.errors).forEach(function (value) {
                            cardDataDisplayError.textContent = value;
                        });
                    } else {
                        alert(result.token);
                        var params = {
                            'token': result.token
                        }
                        PaymentService.testPayment(result.token, onSuccess, onError);

                    }
                }).catch(function(data){
                console.log(data);
            });
        });

        function onSuccess(data, headers) {
            vm.locations = data;
        }

        function onError(error) {
            Notification.error(error.data.message);
        }


        // Submit the form with the token ID.
        function cloverTokenHandler(token) {
            // Insert the token ID into the form so it gets submitted to the server
            var form = document.getElementById('payment-form');
            var hiddenInput = document.createElement('input');
            hiddenInput.setAttribute('type', 'hidden');
            hiddenInput.setAttribute('name', 'cloverToken');
            hiddenInput.setAttribute('value', token);
            form.appendChild(hiddenInput);
            alert(token);
            // Submit the form
            // form.submit();
        }


    }
})();
