'use strict';

CustomerDashboardController.$inject = ['$q', '$state', '$filter', 'googleChartApiPromise', 'CustomerResource',
    'Reporting', 'ApplicationContext', 'LocationResource', 'ApplicationProfile'];

function CustomerDashboardController($q, $state, $filter, googleChartApiPromise, CustomerResource,
                                     Reporting, ApplicationContext, LocationResource, ApplicationProfile) {
    var vm = this;
    vm.showMoreFeedback = showMoreFeedback;
    vm.tabChange = tabChange;
    vm.calcPct = Reporting.calcPct;
    vm.settings = null;
    vm.search = search;
    vm.searchKeyword = null;

    vm.memberTypeEnabled = ApplicationProfile.isCustomerMemberTypeEnabled();
    vm.memberTypeText = ApplicationProfile.upgradeTierText("CustomerMembershipTypeStatistic");
    vm.growthTrendEnabled = ApplicationProfile.isCustomerGrowthTrendEnabled();
    vm.growthTrendText = ApplicationProfile.upgradeTierText("CustomerGrowthTrend");
    vm.openWindow = ApplicationProfile.openAppCloverPage;

    load();

    function load() {
        $q.all([
            loadGeneralData(),
            loadSettings(),
            googleChartApiPromise
        ]).then(loadInitialCharts).then(loadLastCustomers);
    }

    function loadSettings() {
        return LocationResource.getLocationSettings({}, function (settings) {
            vm.settings = settings;
            vm.numberOfAreas = vm.settings.areas.length;
        }).$promise;
    }

    function tabChange(tab) {
        switch (tab) {
            case 'generalStatsTab':
                $(window).resize();
                break;
            case 'visitTab':
                loadVisitsCharts().then($(window).resize());
                break;
            case 'growthTrendTab':
                //loadGrowthTrendData().then(loadGrowthTrendChart).then($(window).resize);
                if(vm.growthTrendEnabled) { loadGrowthTrendData().then(loadGrowthTrendChart); } //.then($(window).resize); //not needed possibly because of new version of google charts
                break;
            case 'memberTypeTab':
                if(vm.memberTypeEnabled) { loadMemberTypeData().then(loadMemberTypeChart); } //.then($(window).resize);
                break;
        }
    }

    function search() {
        $state.go('customers.list', {listType: 'basicSearch', keyword: vm.searchKeyword});
    }

    function showMoreFeedback() {
        ViewState.goToState('customer.feedback', {
            dates: {
                startDate: moment().subtract(1, 'year'),
                endDate: moment()
            }
        });
    }

    function loadGeneralData() {
        return CustomerResource.getDashboardGeneralData({}, function (data) {
            vm.generalData = data;
        }).$promise;
    }

    function loadGrowthTrendData() {
        return CustomerResource.getDashboardGrowthTrendData({}, function (data) {
            vm.growthTrendData = data;
        }).$promise;
    }

    function loadMemberTypeData() {
        return CustomerResource.getDashboardMemberTypeData({}, function (data) {
            vm.memberTypeData = data;
        }).$promise;
    }

    function loadLastCustomers() {
        return CustomerResource.lists(
            {
                listType: 'createdDate',
                page: 0,
                size: 10,
                sort: ['createdDate,desc', 'id']
            },
            function (data) {
                vm.customers = data;
            }).$promise;
    }

    function loadInitialCharts() {
        var deferred = $q.defer();
        // Regulars vs non-regulars
        var dataKnownVSUnknown = new google.visualization.DataTable();
        dataKnownVSUnknown.addColumn('string', 'CustomerType');
        dataKnownVSUnknown.addColumn('number', 'Revenue');
        dataKnownVSUnknown.addColumn({type: 'string', role: 'tooltip'})
        dataKnownVSUnknown.addColumn({type: 'string', role: 'style'})
        dataKnownVSUnknown.addColumn({type: 'string', role: 'annotation'});

        dataKnownVSUnknown.addRows([
            ['Regulars', vm.generalData.regulars.totalSpend, 'Revenue: ' + $filter('currency')(vm.generalData.regulars.totalSpend) + '\nTips: ' + $filter('currency')(vm.generalData.regulars.totalTips) + '\n Visits: ' + vm.generalData.regulars.totalVisits + '\n Avg spend per visit: ' + $filter('currency')(vm.generalData.regulars.avgSpendByVisit), '#1ABC9C', $filter('currency')(vm.generalData.regulars.totalSpend)],
            ['Non-regulars', vm.generalData.nonRegulars.amountSum, 'Revenue: ' + $filter('currency')(vm.generalData.nonRegulars.amountSum) + '\nTips: ' + $filter('currency')(vm.generalData.nonRegulars.tipSum) + '\n Visits: ' + vm.generalData.nonRegulars.visitsCount + '\n Avg spend per visit: ' + $filter('currency')(vm.generalData.nonRegulars.amountAvgPerVisit), '#CC3333', $filter('currency')(vm.generalData.nonRegulars.amountSum)],
        ]);

        var optionsKnownVSUnknown = {
            title: 'Regulars vs non-regulars',
            titleTextStyle: {color: '#23c6c8', fontSize: 14, bold: true},
            //width: 475,
            height: 300,
            vAxis: {
                title: 'Total Spend in ' + ApplicationContext.getCurrencySym(), gridlines: {count: 6}, viewWindow: {min: 0},
                titleTextStyle: {color: '#1ab394', fontSize: 14, bold: true}
            },

            annotations: {
                alwaysOutside: true,
                textStyle: {
                    fontSize: 11,
                    bold: 'true',
                    color: '#000',
                    auraColor: 'none'
                }
            },
            legend: 'none',
            formatters: {}
        };

        vm.chartObjectKnownVSUnknown = {
            type: "ColumnChart",
            options: optionsKnownVSUnknown,
            data: dataKnownVSUnknown
        };

        //vistis - membership pie chart
        vm.chartObjectMembership = {};
        vm.chartObjectMembership.type = "PieChart";

        vm.chartObjectMembership.options = {
            title: '# of customer by membership type',
            titleTextStyle: {color: '#23c6c8', fontSize: 14, bold: true},
            //width: 475,
            height: 325,
            slices: {
                0: {color: '#1ABC9C'},
                1: {color: '#CC9966'},
                2: {color: 'silver'},
                3: {color: 'gold'},
                4: {color: '#E5E4E2'}
            },
            chartArea: {width: '85%', height: '65%'}
        };

        var dataMembership = {};


        dataMembership.cols = [
            {id: "t", label: "Type", type: "string"},
            {id: "s", label: "Number", type: "number"}
        ];


        var pieChartArray = [];
        _.each(vm.generalData.countByLevel, function (item) {
            if (typeof item.customerLevel == "number") {//skip undefined items - in database customerLevel should not be null
                //dataMembership.addRow([vm.settings.memberStatuses[item.customerLevel - 1].name, item.customerCount]);
                var row = {
                    c: [
                        {v: vm.settings.memberStatuses[item.customerLevel - 1].name},
                        {v: item.customerCount}
                    ]
                };
                pieChartArray.push(row);
            }
        });

        vm.chartObjectMembership.data = dataMembership;
        vm.chartObjectMembership.data.rows = pieChartArray;

        deferred.resolve(true);
        return deferred.promise;
    }

    function loadVisitsCharts() {
        var deferred = $q.defer();
        //vistis - general pie chart
        vm.chartObjectVisitGeneral = {};
        vm.chartObjectVisitGeneral.type = "PieChart";

        vm.chartObjectVisitGeneral.options = {
            title: 'Visit statistics',
            titleTextStyle: {color: '#23c6c8', fontSize: 14, bold: true},
            legend: {position: 'right'},
            //width: 450,
            height: 350,

            slices: {
                0: {color: '#1ABC9C'},
                1: {color: '#3399CC'},
                2: {color: '#996699'}
            },
            chartArea: {width: '85%', height: '65%'}
        };

        vm.chartObjectVisitGeneral.data = {
            "cols": [
                {id: "t", label: "Type", type: "string"},
                {id: "s", label: "Number", type: "number"}
            ], "rows": [
                {
                    c: [
                        {v: "Reservation"},
                        {v: vm.generalData.regulars.totalReservationShow}
                    ]
                },
                {
                    c: [
                        {v: "Walk-in seated"},
                        {v: vm.generalData.regulars.totalWalkinSeated}
                    ]
                },
                {
                    c: [
                        {v: "Walk-in waitlisted"},
                        {v: vm.generalData.regulars.totalWaitlistShow}
                    ]
                }
            ]
        };

        //reservation - general pie chart
        vm.chartObjectReservationGeneral = {};
        vm.chartObjectReservationGeneral.type = "PieChart";

        vm.chartObjectReservationGeneral.options = {
            title: 'Reservation statistics',
            titleTextStyle: {color: '#23c6c8', fontSize: 14, bold: true},
            legend: {position: 'right'},
            //width: 450,
            height: 350,

            slices: {
                0: {color: '#1ABC9C'},
                1: {color: '#FF3333'},
                2: {color: '#FFCC33'}
            },
            chartArea: {width: '85%', height: '65%'}
        };

        vm.chartObjectReservationGeneral.data = {
            "cols": [
                {id: "t", label: "Type", type: "string"},
                {id: "s", label: "Number", type: "number"}
            ], "rows": [
                {
                    c: [
                        {v: "Show"},
                        {v: vm.generalData.regulars.totalReservationShow},
                    ]
                },
                {
                    c: [
                        {v: "No Show"},
                        {v: vm.generalData.regulars.totalReservationNoShow}
                    ]
                },
                {
                    c: [
                        {v: "Cancellation"},
                        {v: vm.generalData.regulars.totalReservationCanceled},
                    ]
                }
            ]
        };


        //waitlist - general pie chart
        vm.chartObjectWaitlistGeneral = {};
        vm.chartObjectWaitlistGeneral.type = "PieChart";

        vm.chartObjectWaitlistGeneral.options = {
            title: 'Waitlist statistics',
            titleTextStyle: {color: '#23c6c8', fontSize: 14, bold: true},
            legend: {position: 'right'},
            //width: 450,
            height: 350,

            slices: {
                0: {color: '#1ABC9C'},
                1: {color: '#FF3333'},
                2: {color: '#FFCC33'}
            },
            chartArea: {width: '85%', height: '65%'}

        };

        vm.chartObjectWaitlistGeneral.data = {
            "cols": [
                {id: "t", label: "Type", type: "string"},
                {id: "s", label: "Number", type: "number"}
            ], "rows": [
                {
                    c: [
                        {v: "Show"},
                        {v: vm.generalData.regulars.totalWaitlistShow},
                    ]
                },
                {
                    c: [
                        {v: "No Show"},
                        {v: vm.generalData.regulars.totalWaitlistNoShow}
                    ]
                },
                {
                    c: [
                        {v: "Cancellation"},
                        {v: vm.generalData.regulars.totalWaitlistCanceled},
                    ]
                }
            ]
        };

        deferred.resolve(true);
        return deferred.promise;
    }

    function loadGrowthTrendChart() {
        var deferred = $q.defer();
        // Growth trend
        var dataGrowthTrend = new google.visualization.DataTable();
        dataGrowthTrend.addColumn('string', 'Month');
        dataGrowthTrend.addColumn('number', '# new customers');
        dataGrowthTrend.addColumn({type: 'string', role: 'annotation'});

        _.each(vm.growthTrendData.customerCnt, function (item) {
            dataGrowthTrend.addRow([item.monthName, item.registeredCustomers, '' + item.registeredCustomers]);
        });


        var optionsGrowthTrend = {
            title: 'New customer registrations on monthly basis in last 12 months',
            titleTextStyle: {color: '#23c6c8', fontSize: 14, bold: true},
            legend: {position: 'none'},
            //width: 1050,
            height: 425,
            vAxis: {
                title: '# new customers', gridlines: {count: 6}, viewWindow: {min: 0},
                titleTextStyle: {color: '#1ab394', fontSize: 14, bold: true}
            },
            annotations: {
                alwaysOutside: true,
                textStyle: {
                    fontSize: 11,
                    bold: 'true',
                    color: '#000',
                    auraColor: 'none'
                }
            },
            chartArea: {width: '85%', height: '65%'},
            formatters: {},
            colors: ['#1ABC9C']
        };

        vm.chartObjectGrowthTrend = {
            type: "ColumnChart",
            options: optionsGrowthTrend,
            data: dataGrowthTrend
        };

        deferred.resolve(true);
        return deferred.promise;
    }

    function loadMemberTypeChart() {
        var deferred = $q.defer();
        // Member type stats - comperation
        var dataMemberTypeComperation = new google.visualization.DataTable();
        dataMemberTypeComperation.addColumn('string', 'Member type');
        dataMemberTypeComperation.addColumn('number', 'Customer');
        dataMemberTypeComperation.addColumn('number', 'Revenue');
        dataMemberTypeComperation.addColumn('number', 'Tip');
        dataMemberTypeComperation.addColumn('number', 'Visits');
        dataMemberTypeComperation.addColumn('number', 'No Show');

        _.each(vm.memberTypeData.data, function (item) {
            dataMemberTypeComperation.addRow([vm.settings.memberStatuses[item.customerLevel - 1].name, Number((item.customersPct * 100).toFixed(2)), Number((item.revenuePct * 100).toFixed(2)), Number((item.tipPct * 100).toFixed(2)), Number((item.visitsPct * 100).toFixed(2)), Number((item.noShowPct * 100).toFixed(2))]);
        });

        var optionsMemberTypeComperation = {
            title: 'Share of member types in certain areas (number, revenue, tip, visit, no show rate)',
            titleTextStyle: {color: '#23c6c8', fontSize: 14, bold: true},
            //width: 900,
            height: 300,
            vAxis: {
                title: '% Percentage',
                gridlines: {count: 6}, viewWindow: {min: 0},
                titleTextStyle: {color: '#1ab394', fontSize: 14, bold: true},
                format: '#\'%\''
            },
            hAxis: {
                title: 'Member type',
                titleTextStyle: {color: '#1ab394', fontSize: 14, bold: true},
            },

            annotations: {
                alwaysOutside: true,
                textStyle: {
                    fontSize: 11,
                    bold: 'true',
                    color: '#000',
                    auraColor: 'none'
                }
            },
            //legend: { position: 'top', alignment: 'end'},
            //chartArea: {width: '70%', height: '65%'},
            formatters: {},
            colors: ['#3399FF', '#1ABC9C', '#00CC99', '#CCCC99', '#FF3333']

        };

        vm.chartObjectMemberTypeComperation = {
            type: "ColumnChart",
            options: optionsMemberTypeComperation,
            data: dataMemberTypeComperation
        };

        deferred.resolve(true);
        return deferred.promise;
    }
}

angular.module('seatonApp').controller('CustomerDashboardController', CustomerDashboardController);
