(function () {
    'use strict';

    angular
        .module('seatonApp')
        .controller('CreateWaitlistController', CreateWaitlistController);

    CreateWaitlistController.$inject = ['$rootScope', '$scope', '$state', '$stateParams', 'Tables', 'Notification', '$uibModal',
        'CustomerResource', 'Utils', 'SweetAlert', 'ApplicationProfile', 'ApplicationContext', '$document'];

    function CreateWaitlistController($rootScope, $scope, $state, $stateParams, Tables, Notification, $uibModal, CustomerResource,
                                      Utils, SweetAlert, ApplicationProfile, ApplicationContext, $document) {

        var vm = this;

        vm.showCustomerStatistic = ApplicationProfile.isEventCreateCustomerStatsEnabled();
        vm.showCustomerStatisticText = ApplicationProfile.upgradeTierText("CreateReservation");
        vm.openWindow = ApplicationProfile.openAppCloverPage;


        vm.refreshQueue = function () {
            Tables.getWaitlistQueues(
                {},
                function (data) {
                    vm.waitlistQueues = data;
                }
            );
        }
        vm.refreshQueue();

        vm.phoneRegion = ApplicationContext.getPhoneRegion();
        vm.phonePrefix = ApplicationContext.getPhoneCountryPrefix();
        vm.isInternationalSmsEnabled = ApplicationProfile.isInternationalSmsEnabled();

        if (vm.isInternationalSmsEnabled || $rootScope.isEU) {
            vm.phonePattern = '';
        } else {
            vm.phonePattern = new RegExp('^\\' + ApplicationContext.getPhoneCountryPrefix());
        }

        vm.reformatPhone = function () {
            $scope.numInput = $document.find('#primaryPhone');
            //console.log("vm.reformatPhone:" + $scope.numInput.val());
            if (typeof intlTelInputUtils !== 'undefined') { // utils are lazy loaded, so must check
                //console.log("ng-change:" + vm.customer.primaryPhone);
                var currentText = $scope.numInput.intlTelInput("getNumber", intlTelInputUtils.numberFormat.INTERNATIONAL);
                //console.log("currentText:" + currentText);
                if (typeof currentText === 'string') { // sometimes the currentText is an object :)
                    $scope.numInput.intlTelInput('setNumber', currentText); // will autoformat because of formatOnDisplay=true
                }
            }
        }

        /** Called by the customer search directive when user selects a customer from a list */
        $scope.customerSelected = function (selCust) {

            CustomerResource.get(
                {id: selCust.id},
                function (data) {
                    vm.customer = data;
                    vm.customer.primaryPhone = intlTelInputUtils.formatNumber(vm.customer.primaryPhone, vm.phoneRegion, intlTelInputUtils.numberFormat.INTERNATIONAL);
                    //vm.reformatPhone();
                    vm.customerTotal = vm.customer.totalVisits + vm.customer.totalNoShow + vm.customer.totalCanceled;
                    vm.customerOriginal = Utils.getBasicCustomerData(data);
                }
            );
        }

        vm.resetCustomer = function () {
            // workaround for https://github.com/angular/angular.js/issues/2743
            jQuery("input[name='primaryEmail']").val("");
            jQuery("input[name='primaryPhone']").val("");

            vm.customer = null;
            vm.customerTotal = null;
            vm.customerOriginal = null;
            $scope.customerSearchKeyword = null;
        }

        vm.reset = function () {
            vm.note = null;
            vm.quotedWaitTime = null;
            vm.numberOfPersons = null;
            vm.resetCustomer();
        }

        vm.isAddingDisabled = function () {
            var disabled = !(vm.quotedWaitTime > 0) ||
                !(vm.numberOfPersons > 0) ||
                !vm.customer ||
                !vm.customer.firstName ||
                ($rootScope.isEU==true && !vm.customer.primaryEmail) ||
                $scope.customerForm.primaryEmail.$error.email ||
                !(vm.customer.primaryPhone && $scope.customerForm.primaryPhone.$valid) ||
                (vm.customer.id && !vm.customer.waitlistAllowed);

            return disabled;
        }

        vm.addToWaitlist = function () {

            var currentCustomer = Utils.getBasicCustomerData(vm.customer);
            var customerChangedFlag = currentCustomer.id && !angular.equals(currentCustomer, vm.customerOriginal);

            var event = {
                note: vm.note,
                quotedWaitTime: vm.quotedWaitTime,
                numberOfPersons: vm.numberOfPersons,
                channel: 'WEB',
                existingCustomerUpdated: customerChangedFlag,
                customer: currentCustomer
            };
            Tables.addToWaitlist(
                event,
                function (data) {
                    SweetAlert.swal({
                            title: "Added customer to waitlist",
                            text: "What do you want to do next?",
                            type: "success",
                            showCancelButton: true,
                            cancelButtonColor: "#1a94b3",
                            confirmButtonColor: "#1ab394",
                            cancelButtonText: "See event details",
                            confirmButtonText: "Add new customer to waitlist",
                            closeOnConfirm: true
                        },
                        function (isConfirm) {
                            if (isConfirm) {
                                vm.reset();
                                vm.refreshQueue();
                            } else {
                                $state.go('tables.eventDetails', {eventId: data.id});
                            }
                        });
                }
            );
        }
    }
})();



